import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import TableList from '../../../components/modelo/TableList';


interface Compra {
    id: number;
    nome_terceiro: string;
    quantidade_itens: number;
    observacao: string;
    valor_total: number;
}

const nomeDasColunas: string[] = [
    'Nome do terceiro',
    'Quantidade de Produto',
    'Observação',
    'Valor Total',
];

const fetchData = async (setData: React.Dispatch<React.SetStateAction<Compra[]>>) => {
    try {
        const response = await api.get<Compra[]>("/compras/obterListaCompras", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET",
            },
        });
        console.log(response.data)
        setData(response.data);
    } catch (error) {
        console.error('Erro ao obter dados:', error);
    }
};

const IndexListaCompras: React.FC = (props) => {
    
    const [listaCompras, setListaCompras] = useState<Compra[]>([]);

    useEffect(() => {
        fetchData(setListaCompras);
    }, []);

    const funcaoDesativar = async (dados: Compra) => {
        const confirmaOk = window.confirm("Tem certeza de que deseja desativar esta Compra?");
        if (confirmaOk) {
            try {
                const result = await api.post(
                    "/compras/comprasDisabled/" + dados.id,
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Methods": "POST",
                        },
                    }
                );
                // alert('Desativado com sucesso!');
                fetchData(setListaCompras); // Chama a função para buscar dados novamente
            } catch (error) {
                console.error('Erro ao desativar :', error);
            }
            console.log("Desativar Compra:", dados.id);
        }
    };

    return (
        console.log(listaCompras),
            <HomeAdm>
                <TableList
                    
                    funcaoDesativar={(dados) => funcaoDesativar(dados)}
                    nomeDasColunas={nomeDasColunas}
                    dadosVindosBackEnd={listaCompras}
                    habilitarColunaDeAcao={true}
                    nomeTitulo='Lista de Compras'
                    nomeAdicionar='Adicionar nova Compra'
                    habilitarExclusao={true}
                    linkParaEdicao = '/ListaItens/'
                    keys={['nome_terceiro', 'quantidade_itens', 'observacao', 'valor_total']}
                    definicaoDeChavePrimaria='id'
                    arrayColumns={[]}
                 urlClickBotao={"/administrativo/compra/compraList"}/>
            </HomeAdm>
    );
};

export default IndexListaCompras;
