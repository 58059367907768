import React, { FC } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import ButtonMedio from '../../individual/ButtonMedio';

interface Option {
    id?:string|"";
    value: number;
    text: string;
}

interface SelectProps {
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    options: Option[];
}

interface InputProps {
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

interface ButtonProps {
    name: string;
    function_onClick: () => void;
}

interface Props {
    optionsSelectTabelas: Option[];
    primeiroSelect: SelectProps;
    segundoSelect: SelectProps;
    terceiroSelect: SelectProps;
    segundoInput: InputProps;
    tercerioInput: InputProps;
    quartoInput: InputProps;
    primeiroButton: ButtonProps;
    textArea: string;
    mostrarPrimeiroBloco: boolean;
    mostrarSegundoBloco: boolean;
    mostrarTerceiroBloco: boolean;
    mostrarQuartoBloco: boolean;
    mostrarQuintoBloco: boolean;
    mostrarSextoBloco: boolean;
    mostrarSetimoBloco: boolean;
    colunas_tabela: { nome: string; check: boolean }[];
    marcaDesmarcaTodasColuna: () => void;
    onchangeCheckColunas: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DivAlterada = styled.div`
    background-color: white;
    padding: 20px;
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const FormularioComBlocoAbaixo: FC<Props> = (props) => {
    const options_default: Option[] = [{ value: 0, text: 'Selecione' }];
    const mostrar_colunas = props.colunas_tabela.length > 0;

    return (
        <DivAlterada>
            <Container>
                <Row>
                    {props.mostrarPrimeiroBloco && (
                        <Col sm={12} md={3} lg={3}>
                            <FlexContainer>
                                <label>Tipo de Gerador:</label>
                                <select value={props.primeiroSelect.value} onChange={props.primeiroSelect.onChange}>
                                    {props.optionsSelectTabelas.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select>
                            </FlexContainer>
                        </Col>
                    )}
                    {props.mostrarSegundoBloco && (
                        <Col sm={12} md={3} lg={3}>
                            <FlexContainer>
                                <label>{props.segundoInput.label}:</label>
                                <input type="text" value={props.segundoInput.value} onChange={props.segundoInput.onChange} />
                            </FlexContainer>
                        </Col>
                    )}
                    {props.mostrarTerceiroBloco && (
                        <Col sm={12} md={3} lg={3}>
                            <FlexContainer>
                                <label>Nome Model:</label>
                                <input type="text" value={props.tercerioInput.value} onChange={props.tercerioInput.onChange} />
                            </FlexContainer>
                        </Col>
                    )}
                    {props.mostrarSextoBloco && (
                        <Col sm={12} md={3} lg={3}>
                            <FlexContainer>
                                <label>{props.quartoInput.label}:</label>
                                <input type="text" value={props.quartoInput.value} onChange={props.quartoInput.onChange} placeholder={props.quartoInput.placeholder} />
                            </FlexContainer>
                        </Col>
                    )}
                    {props.mostrarQuartoBloco && (
                        <Col sm={12} md={3} lg={3}>
                            <FlexContainer>
                                <label>{props.segundoSelect.label}:</label>
                                <select value={props.segundoSelect.value} onChange={props.segundoSelect.onChange}>
                                    {props.segundoSelect.options.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select>
                            </FlexContainer>
                        </Col>
                    )}
                    {props.mostrarSetimoBloco && (
                        <Col sm={12} md={3} lg={3}>
                            <FlexContainer>
                                <label>{props.terceiroSelect.label}:</label>
                                <select value={props.terceiroSelect.value} onChange={props.terceiroSelect.onChange}>
                                    {props.terceiroSelect.options.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select>
                            </FlexContainer>
                        </Col>
                    )}
                    {mostrar_colunas && (
                        <Col sm={12} md={12} lg={12}>
                            <hr />
                            <FlexContainer>
                                <label>
                                    <h4>Colunas da Tabela:</h4>
                                </label>
                                <table className="table table-responsive" style={{ height: '200px', overflow: 'auto', display: 'block' }}>
                                    <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>
                                            Selecionar <input type="checkbox" value={-1} checked={true} onClick={props.marcaDesmarcaTodasColuna} />
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.colunas_tabela.map((coluna, index) => (
                                        <tr key={index}>
                                            <td>{coluna.nome}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    id={coluna.nome}
                                                    checked={coluna.check}
                                                    value={coluna.nome}
                                                    onChange={(e) => props.onchangeCheckColunas(e)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </FlexContainer>
                        </Col>
                    )}
                    {props.mostrarQuintoBloco && (
                        <Col sm={12} md={12} lg={12}>
                            <FlexContainer>
                                <ButtonMedio variant="success" onClick={props.primeiroButton.function_onClick} nameButton={props.primeiroButton.name} />
                            </FlexContainer>
                        </Col>
                    )}
                    {/* Coloque uma div de separação com margem de 20px */}
                    <Col sm={12} md={12} lg={12}>
                        <div style={{ margin: '20px' }}></div>
                    </Col>
                    <Col sm={12} md={12} lg={12}>
                        <FlexContainer>
                            <label>Código gerado:</label>
                            <textarea value={props.textArea} style={{ width: '100%', height: '60vh' }} />
                        </FlexContainer>
                    </Col>
                </Row>
            </Container>
        </DivAlterada>
    );
};

export default FormularioComBlocoAbaixo;

