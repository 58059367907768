import React, { FC, useEffect, useState } from 'react';
import {redirect, useParams} from 'react-router-dom';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import FormularioGeneric, { FormData } from '../../../components/agrupamento/FormularioGeneric';

interface ProdutoData {
    id: string;
    nome: string;
    descricao: string;
    preco: string;
    status: string;
}

const IndexFormProduto: FC = () => {
    const { idGet } = useParams<{ idGet: string }>();

    const [dadosDoFormulario, setFormData] = useState<ProdutoData>({
        id: idGet ?? '',
        nome: '',
        descricao: '',
        preco: '',
        status: ''
    });

    useEffect(() => {
        const funcaoBuscarDadosNoBD = async () => {
            try {
                const produtoData = await api.get(`/produto/prodFormEdit/${idGet}`);

                if (produtoData && produtoData.data && produtoData.data.message) {
                    setFormData({
                        id: idGet ?? '',
                        nome: produtoData.data.message.nome || '',
                        descricao: produtoData.data.message.descricao || '',
                        preco: produtoData.data.message.preco || '',
                        status: produtoData.data.message.status || '',
                    });
                }
            } catch (error) {
                console.error('Erro ao obter dados do usuário para edição:', error);
            }
        };

        if (idGet && parseInt(idGet) > 0) {
            funcaoBuscarDadosNoBD();
        } else {
            console.log('ID inválido:', idGet);
        }
    }, [idGet]);

    const data: FormData[] = [
        { type: "text", nomeLabel: 'Nome', value: dadosDoFormulario.nome, sm: 12, md: 6, lg: 12, onChange: (e) => setFormData({ ...dadosDoFormulario, nome: e.target.value }) },
        { type: "text", nomeLabel: 'Descrição', value: dadosDoFormulario.descricao, sm: 12, md: 6, lg: 12, onChange: (e) => setFormData({ ...dadosDoFormulario, descricao: e.target.value }) },
        { type: "text", nomeLabel: 'Preço', value: dadosDoFormulario.preco, sm: 12, md: 6, lg: 12, onChange: (e) => setFormData({ ...dadosDoFormulario, preco: e.target.value }) },
    ];

    const GravarDados = async (dados: ProdutoData) => {
        try {
            const endpoint = dados.id ? `/produto/prodEdit/${dados.id}` : '/produto/prodCreate';

            const dadosEnvio = {
                nome: dados.nome,
                descricao: dados.descricao,
                preco: dados.preco,
                id: ""

            };

            if (dados.id) {
                dadosEnvio.id = dados.id;
            }


            await api.post(endpoint, dadosEnvio, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Methods': 'POST',
                },
            });


            alert(dados.id ? 'Dados atualizados com sucesso!' : 'Dados inseridos com sucesso!');
            return redirect("/prodList");
        } catch (error) {
            console.error('Erro ao gravar/atualizar dados:', error);
        }
    };

    return (
        <HomeAdm>
            <FormularioGeneric
                dadosDoFormulario={dadosDoFormulario}
                function_onClick={GravarDados}
                setFormData={setFormData}
                dataForm={data}
            />
        </HomeAdm>
    );
};

export default IndexFormProduto;

