import React, { ReactNode, MouseEventHandler } from 'react';

interface TableRowProps {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
}

const TableRow = ({ children, onClick = () => null }: TableRowProps) => {
  return <tr onClick={onClick}>{children}</tr>;
};

export default TableRow;
