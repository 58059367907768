import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import TableList from '../../../components/modelo/TableList';
import {  } from 'react-router-dom';



const nomeDasColunas = ['address', 'city'];

const fetchData = async (setData: React.Dispatch<React.SetStateAction<any[]>>) => {
    try {
        const response = await api.get('/paciente/pacienteList', {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Methods': 'GET',
            },
        });
        setData(response.data);
    } catch (error) {
        console.error('Erro ao obter dados:', error);
    }
};

const IndexpacienteList: React.FC = () => {
    const [paciente, setpaciente] = useState<any[]>([]);

    useEffect(() => {
        fetchData(setpaciente);
    }, []);

    const funcaoDesativar = async (userData: any) => {
        const proceed = window.confirm('Tem certeza de que deseja desativar este paciente?');
        if (proceed) {
            try {
                const result = await api.post(
                    '/paciente/pacienteDisabled/' + userData.id,
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Methods': 'POST',
                        },
                    }
                );
                fetchData(setpaciente);
            } catch (error) {
                console.error('Erro ao desativar:', error);
            }
            console.log('Desativar usuário:', userData);
        }
    };

    console.log(paciente);

    return (
        <HomeAdm>
            <TableList
                nomeDasColunas={nomeDasColunas}
                dadosVindosBackEnd={paciente}
                habilitarColunaDeAcao={true}
                habilitarExclusao={true}
                funcaoDesativar={(dados) => funcaoDesativar(dados)}
                linkParaEdicao="/pacienteEdit/"
                nomeTitulo="Lista de paciente"
                nomeAdicionar="Adicionar paciente"
                urlClickBotao="/pacienteCreate/"
                keys={nomeDasColunas}
                definicaoDeChavePrimaria="id"
                arrayColumns={[]}
            />
        </HomeAdm>
    );
};

export default IndexpacienteList;
