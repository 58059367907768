import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import { useParams } from 'react-router-dom';
import ListaItens from '../../../components/modelo/ListaItens';

interface FormData {
    nome_terceiro: string;
    observacao: string;
}

const nomeDasColunas: string[] = [
    'Produto',
    'Descrição',
    'Valor',
];

const ListaDeItensCompras: React.FC = () => {
    const { idGet } = useParams<{ idGet: string }>();
    const [itens, setItens] = useState<any[]>([]);
    const [dadosDoFormulario, setFormData] = useState<FormData>({
        nome_terceiro: '',
        observacao: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/itensCompra/itensCompList/${idGet}`);
                setItens(response.data.message);
            } catch (error) {
                console.error('Erro ao obter dados:', error);
            }
        };

        const obterNomeeObservacao = async () => {
            try {
                const response = await api.get(`/compras/comprasFormEdit/${idGet}`);
                setFormData(response.data.message);
                console.log(response);
            } catch {
                console.error('Erro ao obter dados');
            }
        }
        fetchData();
        obterNomeeObservacao();
    }, [idGet]);

    const alterarNomeTerceiro = async (nome: string) => {
        try {
            setFormData({ ...dadosDoFormulario, nome_terceiro: nome });
            const idCompra = typeof idGet === 'string' ? parseInt(idGet, 10) : 0;

            const endpoint = idCompra > 0 ? `/compras/alterarDadosDaColuna/${idCompra}` : 'Erro';
            const dadosEnvio = {
                nome_coluna: 'nome_terceiro', valor: nome,
                id_compra: idCompra > 0 ? idCompra : 0
            };


            console.log(dadosEnvio);
            const result = await api.post(endpoint, dadosEnvio);
        } catch (error) {
            console.error('Erro ao gravar/atualizar dados:', error);
        }
    };

    const funcaoDesativar = async (dados: any) => {
        const confirmaOk = window.confirm("Tem certeza de que deseja desativar esta Compra?");
        if (confirmaOk) {
            try {
                console.log(dados);
                const obterTodosItens = async () => {
                    try {
                        const response = await api.get(`/itensCompra/itensCompList/${idGet}`);
                        setItens(response.data.message);
                    } catch (error) {
                        console.error('Erro ao obter dados:', error);
                    }
                }
                const fetchData = async () => {
                    try {
                        const response = await api.post(`/itensCompra/itensCompDisabled/${dados.id}`);
                        obterTodosItens();
                    } catch (error) {
                        console.error('Erro ao obter dados:', error);
                    }
                };
                fetchData();
            } catch (error) {
                console.error('Erro ao desativar :', error);
            }
            console.log("Desativar Compra:", dados.id);
        }
    };

    const alterarObservacoes = async (observacao: string) => {
        try {
            setFormData({ ...dadosDoFormulario, observacao: observacao });
            const idCompra = typeof idGet === 'string' ? parseInt(idGet, 10) : 0;

            const endpoint = idCompra > 0 ? `/compras/alterarDadosDaColuna/${idCompra}` : 'Erro';
            const dadosEnvio = {
                nome_coluna: 'observacao', valor: observacao,
                id_compra: idCompra > 0 ? idCompra : 0
            };
            console.log(dadosEnvio);
            const result = await api.post(endpoint, dadosEnvio);
        } catch (error) {
            console.error('Erro ao gravar/atualizar dados:', error);
        }
    };

    const dadosDoInput = [
        { nome: 'Nome', value: dadosDoFormulario.nome_terceiro, sm: 12, md: 6, lg: 12, onChange: (e: React.ChangeEvent<HTMLInputElement>) => alterarNomeTerceiro(e.target.value) },
        { nome: 'Observações', value: dadosDoFormulario.observacao, sm: 12, md: 6, lg: 12, onChange: (e: React.ChangeEvent<HTMLInputElement>) => alterarObservacoes(e.target.value) }
    ]

    return (
        <HomeAdm>
            <ListaItens
                idGet={idGet}
                inputDataListaCompra={dadosDoInput}
                nomeDasColunas={nomeDasColunas}
                dadosVindosBackEnd={itens}
                nomeTitulo='Lista de Itens'
                funcaoDesativar={funcaoDesativar}
                habilitarColunaDeAcao={true}
                habilitarEdicao={false}
                habilitarExclusao={true}
                nomeDoBotaoParaTransicaoDeTela='Ir para Lista de Compras'
                nomeAdicionar='Adicionar Produto'
                urlClickBotao={`/compraList/${idGet}`}
                urlClickBotaoTransicaoTela='/ListaComprasSelect'
                keys={['nome_produto', 'observacao', 'preco']}
                definicaoDeChavePrimaria='field'
                linkParaEdicao={""} />
        </HomeAdm>
    );
};

export default ListaDeItensCompras;
