import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import HomeAdm from '../../../../components/modelo/HomeAdm';
import TableList from '../../../../components/modelo/TableList';
import { useParams } from 'react-router-dom';

interface PublicacaoData {
    id: string;
    // Adicione outras propriedades aqui
}

const fetchData = async (setColunas: (colunas: string[]) => void, setConteudo: (conteudo: PublicacaoData[]) => void, nome_tabela: string) => {
    try {
        const response = await api.get("/extras/obterView/" + nome_tabela, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET",
            },
        });
        const responseColunas = await api.get("/extras/obterColunas/" + nome_tabela, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET",
            },
        });
        setColunas(responseColunas.data.message);
        setConteudo(response.data.message);

    } catch (error) {
        console.error('Erro ao obter dados:', error);
    }
};

const IndexMostraViewList: React.FC = () => {
    const { nome_tabela } = useParams<{ nome_tabela: string }>();
    const [publicacao, setPublicacao] = useState<PublicacaoData[]>([]);
    const [nomeDasColunas, setNomeDasColunas] = useState<string[]>([]);

    useEffect(() => {
        if (!nome_tabela) return;
        fetchData(setNomeDasColunas, setPublicacao, nome_tabela);
    }, [nome_tabela]);

    const funcaoDesativar = async (publiData: PublicacaoData) => {
        const proceed = window.confirm("Tem certeza de que deseja desativar este usuário?");
        if (proceed) {
            try {
                if (!nome_tabela) return;
                const result = await api.post(
                    "/publicacao/publicacaoDisabled/" + publiData.id,
                    {},
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Methods": "POST",
                        },
                    }
                );
                fetchData(setNomeDasColunas, setPublicacao, nome_tabela); // Chama a função para buscar dados novamente
            } catch (error) {
                console.error('Erro ao desativar:', error);
            }
            console.log("Desativar usuário:", publiData);
        }
    };

    return (
        <HomeAdm>
            <TableList
                nomeDasColunas={nomeDasColunas}
                dadosVindosBackEnd={publicacao}
                habilitarColunaDeAcao={false}
                habilitarExclusao={false}
                mostrarBotaoAdicionar={false}
                nomeTitulo={"VIEW: " + nome_tabela}
                linkParaEdicao='/publicacaoEdit/'
                keys={nomeDasColunas}
                definicaoDeChavePrimaria='id'
                funcaoDesativar={funcaoDesativar}
                arrayColumns={[]}
             urlClickBotao={'/teste'}/>
        </HomeAdm>
    );
};

export default IndexMostraViewList;
