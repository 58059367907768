import React, { useState } from 'react';

import TableRow from '../individual/TableRow';
import TableCell from '../individual/TableCell';
import TableDetails from '../agrupamento/TableDetails';
import CabecalhoBasico from '../squad/CabecalhoBasico';
import InputGeneration from '../agrupamento/InputGeneration';
import ButtonMedio from '../individual/ButtonMedio';

interface Props {
    fieldsSegundaDiv: any;
    nomeDasColunas: any;
    dadosVindosBackEnd: any;
    mostrarBotaoAdicionar: any;
    urlClickBotao?: string;
    nomeAdicionar?: string;
    nomeTitulo?: string;
    funcaoDesativar: (data:any)=>void;
    habilitarColunaDeAcao: any;
    habilitarExclusao: any;
    // funcaoBuscarDadosNoBD: any;
    linhaSelecionada: any;
    irParaOutraTela: (data:any,index:number|null)=>void;
    funcaoParaRedirecionamento: any;
    keys: any;
    linkParaEdicao: any;
    definicaoDeChavePrimaria: any;
    funcaoParaSalvar: any;
    arrayColumns?: string[];
    habilitarEdicao?: boolean;
    limparDadosDoInput?: (data:any)=>void;
}

const TableListWithOnClick: React.FC<Props> = ({
                                                   fieldsSegundaDiv,
                                                   nomeDasColunas,
                                                   dadosVindosBackEnd,
                                                   mostrarBotaoAdicionar,
                                                   urlClickBotao = "/",
                                                   nomeAdicionar = "Adicionar",
                                                   nomeTitulo = "Criar Compras",
                                                   funcaoDesativar=(dados:any)=>null,
                                                   habilitarColunaDeAcao,
                                                   habilitarExclusao,
                                                   // funcaoBuscarDadosNoBD,
                                                   linhaSelecionada,
                                                   irParaOutraTela=(data:any,index:number)=>null,
                                                   funcaoParaRedirecionamento,
                                                   keys,
                                                   linkParaEdicao,
                                                   definicaoDeChavePrimaria,
                                                   funcaoParaSalvar,
                                                    arrayColumns=[],
                                                      habilitarEdicao = false,
                                                      limparDadosDoInput = () => null,
                                               }) => {
    const MostrarMensagemdeErro = () => (
        <TableRow>
            <TableCell keys={[]} data={['Não há dados disponíveis']} colSpan={nomeDasColunas.length} align="center"></TableCell>
        </TableRow>
    );

    return (
        <>
            <CabecalhoBasico
                urlClickBotao={urlClickBotao}
                nomeAdicionar={nomeAdicionar}
                nomeTitulo={nomeTitulo}
                mostrarBotaoAdicionar={mostrarBotaoAdicionar}
            />

            {linhaSelecionada === null ? (
                <TableDetails
                    keys={keys}
                    onClickLinhaTabela={(objetoRecebido: any, index: number) => irParaOutraTela(objetoRecebido, index)}  // Adicionado o id e o index para a função irParaOutraTela
                    linkParaEdicao={linkParaEdicao}
                    nomeDasColunas={nomeDasColunas}
                    dadosVindosBackEnd={dadosVindosBackEnd}
                    funcaoDesativar={funcaoDesativar}
                    // funcaoBuscarDadosNoBD={funcaoBuscarDadosNoBD}
                    habilitarColunaDeAcao={habilitarColunaDeAcao}
                    habilitarExclusao={habilitarExclusao}
                    definicaoDeChavePrimaria={definicaoDeChavePrimaria}
                    arrayColumns={arrayColumns}
                    habilitarEdicao={habilitarEdicao}
                >
                    {dadosVindosBackEnd.message ? (
                        dadosVindosBackEnd.map((data: any, index: number) => (
                            <TableRow key={index} onClick={() => irParaOutraTela(data.objetoRecebido, index)}>
                                <TableCell data={data} keys={nomeDasColunas} />
                            </TableRow>
                        ))
                    ) : (
                        <MostrarMensagemdeErro />
                    )}
                </TableDetails>
            ) : null}

            {linhaSelecionada !== null && (
                <>
                    <InputGeneration Fields={fieldsSegundaDiv} />
                    <ButtonMedio variant={'success'} onClick={() => funcaoParaSalvar()} nameButton='Salvar' />
                    <ButtonMedio variant={'danger'} onClick={() => funcaoParaRedirecionamento()} nameButton='Cancelar e Ir para Compra List' />
                </>
            )}
        </>
    );
};

export default TableListWithOnClick;
