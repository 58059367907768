import React, { ReactNode, FC } from 'react';

interface TableColumnsProps {
    children: ReactNode;
}

const TableColumns: FC<TableColumnsProps> = ({ children }) => {
    return <th>{children}</th>;
};

export default TableColumns;
