import React,{ReactNode} from 'react';
import { redirect } from 'react-router-dom';
import ButtonMedio from '../individual/ButtonMedio';
import Title from '../individual/Title';

type CabecalhoBotaoDuploProps = {
    urlClickBotao: string;
    urlClickBotaoTransicaoTela: string;
    nomeTitulo: string;
    mostrarBotaoAdicionar: boolean;
    nomeAdicionar: string;
    nomeDoBotaoParaTransicaoDeTela: string;
};

const CabecalhoBotaoDuplo: React.FC<CabecalhoBotaoDuploProps> = (props) => {
    

    const routeButton = () => {
        return redirect(props.urlClickBotao);
        // history.push(props.urlClickBotao);
    };

    const routeButtonTransitionScreen = () => {
        return redirect(props.urlClickBotaoTransicaoTela);
        // history.push(props.urlClickBotaoTransicaoTela);
    };

    interface DivPrincipalProps {
        children: ReactNode;
    }

    const DivPrincipal: React.FC<DivPrincipalProps> = ({ children }) => {
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                }}
            >
                {children}
            </div>
        );
    };

    interface DivTitleListItensProps {
        children: ReactNode;
    }

    const DivTitleListItens: React.FC<DivTitleListItensProps> = ({ children }) => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>;
    };

    interface DivButtonAlignsProps {
        children: ReactNode;
    }

    const DivButtonAligns: React.FC<DivButtonAlignsProps> = ({ children }) => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>;
    };



    return (
            <DivPrincipal>
                <DivTitleListItens>
                    <Title titulo={props.nomeTitulo}></Title>
                </DivTitleListItens>
                {props.mostrarBotaoAdicionar && (
                    <DivButtonAligns>
                        <ButtonMedio variant='primary' onClick={routeButton} nameButton={props.nomeAdicionar}></ButtonMedio>
                        <div style={{ marginLeft: '8px' }}></div> {/* Add space between the buttons (adjust as necessary) */}
                        <ButtonMedio variant='success' onClick={routeButtonTransitionScreen} nameButton={props.nomeDoBotaoParaTransicaoDeTela}></ButtonMedio>
                    </DivButtonAligns>
                )}
            </DivPrincipal>
    );
};

export default CabecalhoBotaoDuplo;
