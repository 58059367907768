import React from 'react';
import Button from 'react-bootstrap/Button';

interface Props {
  variant: string;
  onClick: () => void;
  nameButton: string;
}
const ButtonMedio = ({ variant, onClick, nameButton }:Props) => {
  return (
    <Button className='nv1b' variant={variant} onClick={onClick}>
      {nameButton}
    </Button>
  );
};

export default ButtonMedio;
