import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';
import HomeAdm from '../../../../components/modelo/HomeAdm';
import FormularioComBlocoAbaixo from "../../../../components/agrupamento/FormularioComBlocoAbaixo/FormularioComBlocoAbaixo";

interface Option {
    id?: string | "";
    value: number;
    text: string;
}

interface SelectProps {
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    options: Option[];
}

interface InputProps {
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

interface ButtonProps {
    name: string;
    function_onClick: () => void;
}

interface Coluna {
    nome: string;
    check: boolean;
    onchange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface FormData {
    tipo: string;
    nomeTabela: string;
    nomeModulo: string;
    model: string;
    controller: string;
    textArea: string;
    tipoModeloPagina: string;
    validation: string;
}

interface Props {
    optionsSelectTabelas: Option[];
    primeiroSelect: SelectProps;
    segundoSelect: SelectProps;
    terceiroSelect: SelectProps;
    segundoInput: InputProps;
    tercerioInput: InputProps;
    quartoInput: InputProps;
    primeiroButton: ButtonProps;
    textArea: string;
    mostrarPrimeiroBloco: boolean;
    mostrarSegundoBloco: boolean;
    mostrarTerceiroBloco: boolean;
    mostrarQuartoBloco: boolean;
    mostrarQuintoBloco: boolean;
    mostrarSextoBloco: boolean;
    mostrarSetimoBloco: boolean;
    colunas_tabela: Coluna[];
    marcaDesmarcaTodasColuna: () => void;
    onchangeCheckColunas: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeInputModel: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const IndexFormGenerateModelo: FC = () => {
    const { idGet } = useParams();
    const [listaDeTabelas, setListaDeTabelas] = useState<Option[]>([]);
    const [dadosDoFormulario, setFormData] = useState<FormData>({
        tipo: "",
        nomeTabela: "",
        nomeModulo: '',
        model: '',
        controller: '',
        textArea: '',
        tipoModeloPagina: '',
        validation: ''
    });
    const [colunas_tabela, setColunas_tabela] = useState<Coluna[]>([]);

    const optionsSelectTipo: Option[] = [
        { value: 0, text: 'Selecione' },
        { value: 1, text: 'Controller' },
        { value: 2, text: 'Model' },
        { value: 3, text: 'Route' },
        { value: 4, text: 'pagina' },
        { value: 5, text: 'Teste Unitário' },
        { value: 6, text: 'Rota manual de Teste' },
        { value: 7, text: 'Método manual de Teste' },
        { value: 8, text: 'Permissoes Colunas' },
        { value: 9, text: 'Validations Colunas' }
    ];

    const optionsSelectTipoModelo: Option[] = [
        { value: 0, text: 'Selecione' },
        { value: 1, text: 'TableList' },
        { value: 2, text: 'FormGeneric' }
    ];

    useEffect(() => {
        const funcaoBuscarViewsBD = async () => {
            try {
                const TabelasData = await api.get(`/extras/mostrarTabelas`, {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Methods": "GET",
                    },
                });

                if (TabelasData.data.length > 0) {
                    const dadosEnvio: Option[] = [{ value: 0, text: 'Selecione' }];
                    TabelasData.data.forEach((item: { id: string; nome: string }) => {
                        dadosEnvio.push({ id: item.id, value: dadosEnvio.length, text: item.nome });
                    });
                    setListaDeTabelas(dadosEnvio);
                }
            } catch (error) {
                console.error('Erro ao obter dados do usuário para edição:', error);
            }
        };

        funcaoBuscarViewsBD();
    }, [idGet]);

    const buscarColunasTabela = async (nomeTabela: string) => {
        try {
            const response = await api.get('/extras/obterColunas/' + nomeTabela, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Methods": "GET",
                },
            });

            const colunas_com_check: Coluna[] = response.data.message.map((item: string) => ({
                nome: item,
                check: true,
                onchange: (e: React.ChangeEvent<HTMLInputElement>) => console.log(e)
            }));

            setColunas_tabela(colunas_com_check);
        } catch (error) {
            console.error('Erro ao obter dados do usuário para edição:', error);
        }
    };

    const onchangeCheckColunas = (e: React.ChangeEvent<HTMLInputElement>) => {
        setColunas_tabela(colunas_tabela.map((item) => {
            if (item.nome === e.target.value) {
                return { ...item, check: e.target.checked }
            }
            return item;
        }));
    };

    const marcaDesmarcaTodasColuna = () => {
        setColunas_tabela(colunas_tabela.map((item) => ({ ...item, check: !item.check })));
    };

    const GravarDados = async () => {
        try {
            const dados: FormData = {
                tipo: dadosDoFormulario.tipo,
                nomeTabela: dadosDoFormulario.nomeTabela,
                nomeModulo: dadosDoFormulario.nomeModulo,
                model: dadosDoFormulario.model,
                controller: dadosDoFormulario.controller,
                textArea: dadosDoFormulario.textArea,
                tipoModeloPagina: dadosDoFormulario.tipoModeloPagina,
                validation: dadosDoFormulario.validation
            };

            const result = await api.post('/extras/gerarModelo', dados, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Methods': 'POST',
                },
            });

            if (result.data.message) {
                setFormData({ ...dadosDoFormulario, textArea: result.data.message });
            }
        } catch (error) {
            console.error('Erro ao gerar modelo:', error);
        }
    };


    return (
        <HomeAdm>
            <FormularioComBlocoAbaixo
                optionsSelectTabelas={listaDeTabelas}
                primeiroSelect={{
                    label: 'Tipo de Gerador:',
                    value: dadosDoFormulario.tipo,
                    onChange: (e) => { setFormData({ ...dadosDoFormulario, tipo: e.target.value }) },
                    options: optionsSelectTipo
                }}
                segundoSelect={{
                    label: 'Tabela:',
                    value: dadosDoFormulario.nomeTabela,
                    onChange: (e) => {
                        setFormData({ ...dadosDoFormulario, nomeTabela: e.target.value });
                        if (dadosDoFormulario.tipo === 'pagina' || dadosDoFormulario.tipo === 'Permissoes' || dadosDoFormulario.tipo === 'Validations Colunas') {
                            buscarColunasTabela(e.target.value);
                        }
                    },
                    options: listaDeTabelas
                }}
                terceiroSelect={{
                    label: 'Modelo da Pagina:',
                    value: dadosDoFormulario.tipoModeloPagina,
                    onChange: (e) => { setFormData({ ...dadosDoFormulario, tipoModeloPagina: e.target.value }) },
                    options: optionsSelectTipoModelo
                }}
                segundoInput={{
                    label: 'Nome do Módulo:',
                    value: dadosDoFormulario.nomeModulo,
                    onChange: (e) => setFormData({ ...dadosDoFormulario, nomeModulo: e.target.value })
                }}
                tercerioInput={{
                    label: 'Nome Model:',
                    value: dadosDoFormulario.model,
                    onChange: (e) => setFormData({ ...dadosDoFormulario, model: e.target.value })
                }}
                quartoInput={{
                    label: 'Nome Controller(Opcional):',
                    placeholder: 'Exemplo',
                    value: dadosDoFormulario.controller,
                    onChange: (e) => setFormData({ ...dadosDoFormulario, controller: e.target.value })
                }}
                primeiroButton={{
                    name: 'Gerar',
                    function_onClick: GravarDados
                }}
                textArea={dadosDoFormulario.textArea}
                marcaDesmarcaTodasColuna={marcaDesmarcaTodasColuna}
                onchangeCheckColunas={onchangeCheckColunas}
                colunas_tabela={colunas_tabela}
                // onChangeInputModel={(e) => setFormData({ ...dadosDoFormulario, model: e.target.value })}
                mostrarTerceiroBloco={dadosDoFormulario.tipo === 'Controller'}
                mostrarSextoBloco={dadosDoFormulario.tipo === 'Route' || dadosDoFormulario.tipo === 'Rota manual de Teste' || dadosDoFormulario.tipo === 'pagina'}
                mostrarQuartoBloco={dadosDoFormulario.tipo !== 'Route' && dadosDoFormulario.tipo !== 'Rota manual de Teste'}
                mostrarSetimoBloco={dadosDoFormulario.tipo === 'pagina'}
             mostrarPrimeiroBloco={false}
                mostrarQuintoBloco={false}
             mostrarSegundoBloco={false}
            />
        </HomeAdm>
    );
};

export default IndexFormGenerateModelo;
