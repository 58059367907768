import React, { useState, useEffect } from 'react';
import { useParams,redirect } from 'react-router-dom';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import FormularioGeneric, { FormData } from '../../../components/agrupamento/FormularioGeneric';

interface FormPublicacao {
    id: number;
    titulo: string;
    subtitulo: string;
    url_imagem: string;
    conteudo: string;
}

const IndexFormPublicacao: React.FC = () => {
    const { idGet } = useParams<{ idGet: string }>();


    const [dadosDoFormulario, setFormData] = useState<FormPublicacao>({
        id: 0,
        titulo: '',
        subtitulo: '',
        url_imagem: '',
        conteudo: '',
    });

    useEffect(() => {
        const funcaoBuscarDadosNoBD = async () => {
            try {
                const publicacaoData = await api.get(`/publicacao/publicacaoFormEdit/${idGet}`);

                if (publicacaoData && publicacaoData.data && publicacaoData.data.message && idGet != null) {

                        setFormData({
                            id: parseInt(idGet),
                            titulo: publicacaoData.data.message.titulo || '',
                            subtitulo: publicacaoData.data.message.subtitulo || '',
                            url_imagem: publicacaoData.data.message.url_imagem || '',
                            conteudo: publicacaoData.data.message.conteudo || '',
                        });

                }
            } catch (error) {
                console.error('Erro ao obter dados para edição:', error);
            }
        };

        if (idGet && parseInt(idGet) > 0) {
            funcaoBuscarDadosNoBD();
        } else {
            console.log('ID inválido:', idGet);
        }
    }, [idGet]);

    const data: FormData[] = [
        { type: "text", nomeLabel: 'Titulo', value: dadosDoFormulario.titulo, sm: 12, md: 6, lg: 12, onChange: (e) => setFormData({ ...dadosDoFormulario, titulo: e.target.value }) },
        { type: "text", nomeLabel: 'Subtitulo', value: dadosDoFormulario.subtitulo, sm: 12, md: 6, lg: 12, onChange: (e) => setFormData({ ...dadosDoFormulario, subtitulo: e.target.value }) },
        { type: "text", nomeLabel: 'URL da Imagem', value: dadosDoFormulario.url_imagem, sm: 12, md: 6, lg: 12, onChange: (e) => setFormData({ ...dadosDoFormulario, url_imagem: e.target.value }) },
        { type: "text", nomeLabel: 'Conteúdo', value: dadosDoFormulario.conteudo, sm: 12, md: 6, lg: 12, onChange: (e) => setFormData({ ...dadosDoFormulario, conteudo: e.target.value }) },
    ];

    const GravarDados = async (dados: FormPublicacao) => {
        try {
            const endpoint = parseInt(String(dados.id)) > 0 ? `/publicacao/publicacaoEdit/${dados.id}` : '/publicacao/publicacaoCreate';

            const dadosEnvio = {
                titulo: dados.titulo,
                subtitulo: dados.subtitulo,
                url_imagem: dados.url_imagem,
                conteudo: dados.conteudo,
                id: parseInt(String(dados.id))|0

            };

            if (parseInt(String(dados.id)) > 0) {
                dadosEnvio.id = dados.id;
            }

            const result = await api.post(endpoint, dadosEnvio, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Methods': 'POST',
                },
            });

            alert(parseInt(String(dados.id)) > 0 ? 'Dados atualizados com sucesso!' : 'Dados inseridos com sucesso!');
            return redirect('/publicacaoList');
            // history.push('/publicacaoList');
        } catch (error) {
            console.error('Erro ao gravar/atualizar dados:', error);
        }
    };

    return (
        <HomeAdm>
            <FormularioGeneric
                dadosDoFormulario={dadosDoFormulario}
                function_onClick={GravarDados}
                setFormData={setFormData}
                dataForm={data}
            />
        </HomeAdm>
    );
};

export default IndexFormPublicacao;
