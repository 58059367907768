import React, { FC } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import ButtonMedio from '../individual/ButtonMedio';
import InputGeneration from './InputGeneration';

const DivAlterada = styled.div`
  background-color: white;
  padding: 20px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DivButtonSave = styled.div`
  width: 100%;
  padding-top: 20px;
`;

export interface FormData {
  type: "text" | "personalizado" | "number" | "password" | "email" | "url" | "tel" | "search" | "hidden";
  component?: FC<any>;
  sm?: number | 12;
  md?: number | 12;
  lg?: number | 12;
  nomeLabel?: string | "";
  value?: string | "";
  onChange?: (e: any) => void;
}


interface FormularioGenericProps {
  dadosDoFormulario: any;
  function_onClick: (data: any) => void;
  setFormData?: void|null|React.Dispatch<React.SetStateAction<any>>;
  dataForm: FormData[];
}

const FormularioGeneric: FC<FormularioGenericProps> = (props) => {
  const { dadosDoFormulario, function_onClick, setFormData, dataForm } = props;

  return (
      console.log('dataForm', dataForm),
      <DivAlterada>
        <Container>
          <Row>
            <InputGeneration Fields={dataForm} />
            <DivButtonSave>
              <Col sm={12} md={6} lg={3}>
                <FlexContainer>
                  <ButtonMedio variant='success' onClick={() => function_onClick(dadosDoFormulario)} nameButton="Salvar" />
                </FlexContainer>
              </Col>
            </DivButtonSave>
          </Row>
        </Container>
      </DivAlterada>
  );
};



export default FormularioGeneric;
