import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import RegisterIndexLayout from "../../../components/modelo/login/RegisterIndexLayout";

const LoginIndex: React.FC = () => {
    return (
        <RegisterIndexLayout />
    );
};

export default LoginIndex;
