import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { redirect } from 'react-router-dom';
import HomeAdm from '../../../../components/modelo/HomeAdm';
import TableListWithOnClick from '../../../../components/modelo/TableListWithOnClick';


const nomeDasColunas = [
    'nome',
];

interface Publicacao {
    id: string;
    nome: string;
}

const fetchData = async (setData: React.Dispatch<React.SetStateAction<Publicacao[]>>) => {
    try {
        const response = await api.get("/extras/mostrarTabelas", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET",
            },
        });
        setData(response.data);
    } catch (error) {
        console.error('Erro ao obter dados:', error);
    }
};

const IndexMostrarTabelasList: React.FC = (props) => {
    const [publicacao, setPublicacao] = useState<Publicacao[]>([]);

    useEffect(() => {
        fetchData(setPublicacao);
    }, []);

    const irParaOutraTela = (objetoRecebido: Publicacao, index: number|null) => {
        // Redirecione para /extras/mostrarTabelas/ + objetoRecebido.id
        return redirect('/showtable/' + objetoRecebido.nome)
        // history.push('/showtable/' + objetoRecebido.nome);
    };

    return (
        <HomeAdm>
            <TableListWithOnClick
                funcaoDesativar={() => null}
                nomeDasColunas={nomeDasColunas}
                dadosVindosBackEnd={publicacao}
                habilitarColunaDeAcao={false}
                habilitarExclusao={true}
                linkParaEdicao='/publicacaoEdit/'
                keys={['nome']}
                definicaoDeChavePrimaria='id'
                fieldsSegundaDiv={null}
                nomeTitulo='Tabelas'
                nomeAdicionar='Adicionar Produto'
                urlClickBotao='/prodCreate/'
                funcaoParaSalvar={() => null}
                limparDadosDoInput={() => null}
                irParaOutraTela={(objeto, index:number|null) => irParaOutraTela(objeto, index)}
                linhaSelecionada={null}
                funcaoParaRedirecionamento={() => null}
             mostrarBotaoAdicionar={false}
            />
        </HomeAdm>
    );
};

export default IndexMostrarTabelasList;
