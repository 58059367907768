import React, { FC, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReorder, faTrash } from '@fortawesome/free-solid-svg-icons';
import Table from '../individual/Table';
import TableHead from '../individual/TableHead';
import TableRow from '../individual/TableRow';
import TableColumns from '../individual/TableColumns';
import TableBody from '../individual/TableBody';
import TableCell from '../individual/TableCell';
import TableData from '../individual/TableData';
import { Link } from 'react-router-dom';

interface TableDetailsProps {
  nomeDasColunas: string[];
  dadosVindosBackEnd: any[];
  arrayColumns: string[];
  definicaoDeChavePrimaria: string;
  onClickLinhaTabela: (data: any,index:number) => void;
  habilitarColunaDeAcao?: boolean;
  habilitarExclusao?: boolean;
  habilitarEdicao: boolean;
  linkParaEdicao?: string;
  funcaoDesativar?: (data: any, index?: number) => void;
  keys: string[];
  children?: ReactNode;
}


// debugger;
const TableDetails: FC<TableDetailsProps> = (props) => {
  const {
    nomeDasColunas,
    dadosVindosBackEnd,
    arrayColumns,
    definicaoDeChavePrimaria,
    onClickLinhaTabela=(data: any, index?: number) => null,
    habilitarColunaDeAcao=true,
    habilitarExclusao=true,
    habilitarEdicao,
    linkParaEdicao="/",
    funcaoDesativar=()=>null,
    keys,
    children = null,
  } = props;

  return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              {arrayColumns.map((column) => (
                  <TableColumns key={column}>{column}</TableColumns>
              ))}
              {habilitarColunaDeAcao && <TableColumns key="action">Ação</TableColumns>}
            </TableRow>
          </TableHead>
          <TableBody>
            {dadosVindosBackEnd.map((data, index) => (
                <TableRow key={index} onClick={() => onClickLinhaTabela(data,index)}>
                  {/*sabendo que TableCell é assim
                  interface Props {
                      data: Record<string, any>;
                      keys: string[];
                    }

                    const TableCell: React.FC<Props> = ({ data, keys }) => (
                        <>
                          {keys.map((key) => (
                              <TableData key={key}>{data[key]}</TableData>
                          ))}
                        </>
                    );
                  */}

                  {/*<TableCell data={data} keys={keys} />*/}
                  <TableCell data={{ ...data, idGet: data[definicaoDeChavePrimaria] }} keys={keys} />
                  {habilitarColunaDeAcao && (
                      <TableData>
                        <div className="button-group" style={{ width: '100px' }}>
                          {habilitarExclusao && (
                              <button
                                  type="button"
                                  className="btn btn-danger"
                                  style={{
                                    backgroundColor: 'white',
                                    border: '1px solid white',
                                  }}
                                  onClick={() => funcaoDesativar(data)}
                              >
                                <FontAwesomeIcon icon={faTrash} style={{ color: 'gray' }} />
                              </button>
                          )}
                          {habilitarEdicao && (
                              <Link to={linkParaEdicao + data[definicaoDeChavePrimaria]}>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{
                                      backgroundColor: 'white',
                                      border: '1px solid white',
                                    }}
                                >
                                  <FontAwesomeIcon icon={faReorder} style={{ color: 'gray' }} />
                                </button>
                              </Link>
                          )}
                        </div>
                      </TableData>
                  )}
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
  );
};

export default TableDetails;
