import React from 'react';
import TableData from '../individual/TableData';

interface Props {
  data: Record<string, any>;
  keys: string[];
  colSpan?: number;
    align?: "center" | "left" | "right" | "justify" | "char";
}

const TableCell: React.FC<Props> = ({ data, keys,colSpan=0,align="left" }) => (
    <>
      {keys.map((key) => (
          <TableData key={key}>{data[key]}</TableData>
      ))}
    </>
);

export default TableCell;
