import React, { ReactNode } from 'react';

type TableBodyProps = {
    children: ReactNode;
};

const TableBody: React.FC<TableBodyProps> = (props) => {
    return <tbody>{props.children}</tbody>;
};

export default TableBody;