// components/Aside.js
import React from 'react';
import styled from 'styled-components';

const AsideContainer = styled.aside`
  background-color: #eee;
  width: 200px;
  height: 100%;
`;

const Aside = () => {
    return <AsideContainer>Aside Content</AsideContainer>;
};

export default Aside;
