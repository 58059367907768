import React from 'react';

interface TitleProps {
  titulo: string;
}

const Title: React.FC<TitleProps> = ({ titulo }) => {
  return (
      <h3>
        {titulo}
      </h3>
  );
};

export default Title;
