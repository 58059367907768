import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import {  useParams, redirect } from 'react-router-dom';
import TableListWithOnClick from '../../../components/modelo/TableListWithOnClick';

interface Produto {
    id: number;
    nome: string;
    descricao: string;
    preco: number;
}

const nomeDasColunas: string[] = [
    'Nome',
    'Descrição',
    'Preço',
];


const fetchData = async (setData: React.Dispatch<React.SetStateAction<Produto[]>>) => {
    try {
        const response = await api.get("/produto/prodList", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET",
            },
        });
        setData(response.data);
    } catch (error) {
        console.error('Erro ao obter dados:', error);
    }
};

const IndexFormCompras: React.FC = (props) => {

    const [produto, setProdutos] = useState<Produto[]>([]);
    const [idProdutodeEnvio, setIdProdutosdeEnvio] = useState<number>(0)
    const [linhaSelecionada, incluirLinhaSelecionada] = useState<number | null>(null);
    const [idCompra, setIdCompra] = useState<number>(0);
    


    let idGetNumber: number=0;
    const { idGet } = useParams()
    if (idGet != null) {
         idGetNumber = parseInt(idGet, 10);
    }

    const funcaoParaSalvar = async (observacao: string, id_produto: number, id_compra: number) => {
        console.log(observacao, id_produto);
        try {
            const endpoint = id_compra > 0 ? `/compras/comprasEdit/${id_compra}` : '/compras/comprasCreate';

            const dadosEnvio = {
                observacao: observacao,
                id_produto: id_produto,
                id_compra: id_compra
            };

            if (id_compra > 0) {
                dadosEnvio.id_compra = id_compra;
            }

            const result = await api.post(endpoint, dadosEnvio, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Methods': 'POST',
                },
            });

            alert(id_compra > 0 ? 'Id n° ' + result.data.message.id + ' atualizados com sucesso!' : 'Id n° ' + result.data.message.id + ' inserido com sucesso!');
            return redirect(`/ListaItens/${result.data.message.id}`)
            // history.push(`/ListaItens/${result.data.message.id}`);
        } catch (error) {
            console.error('Erro ao gravar/atualizar dados:', error);
        }
    }


    useEffect(() => {
        console.log(idProdutodeEnvio);
    }, [idProdutodeEnvio]);

    const limparDadosDoInput = () => {
        setFormData({ ...dadosDoFormulario, observacao: '' })
    }

    useEffect(() => {
        fetchData(setProdutos);

        console.log(idGetNumber);

        if (idGetNumber) {
            setIdCompra(idGetNumber);
        }
    }, []);

    interface FormData {
        observacao: string;
    }

    const [dadosDoFormulario, setFormData] = useState<FormData>({
        observacao: '',
    });

    const irParaOutraTela = (objetoRecebido: Produto, index: number|null) => {
        if (linhaSelecionada === index) {
            incluirLinhaSelecionada(null);
        } else {
            incluirLinhaSelecionada(index);
            setIdProdutosdeEnvio(objetoRecebido.id);
        }
    };

    const funcaoParaRedirecionamento = () => {
        limparDadosDoInput();
        incluirLinhaSelecionada(null);

    };

    const dadosDoInput = [{ nome: 'Observações', value: dadosDoFormulario.observacao, sm: 12, md: 6, lg: 12, onChange: (e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...dadosDoFormulario, observacao: e.target.value }) }]


    const funcaoDesativar = async (produtoData: Produto) => {
        const confirmacaoSimOuNao = window.confirm("Tem certeza de que deseja desativar esta compra?");
        if (confirmacaoSimOuNao) {
            try {
                const result = await api.post(
                    "/produto/prodDisabled/" + produtoData.id,
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Methods": "POST",
                        },
                    }
                );
                // alert('Desativado com sucesso!');
                fetchData(setProdutos); // Chama a função para buscar dados novamente
            } catch (error) {
                console.error('Erro ao desativar :', error);
            }
            console.log("Desativar usuário:", produtoData);
        }
    };

    return (
        console.log(idCompra),
            <HomeAdm>
                <TableListWithOnClick
                    nomeDasColunas={nomeDasColunas}
                    dadosVindosBackEnd={produto}
                    habilitarColunaDeAcao={false}
                    habilitarExclusao={false}
                    fieldsSegundaDiv={dadosDoInput}
                    funcaoDesativar={(dados) => funcaoDesativar(dados)}
                    linkParaEdicao='/prodEdit/'
                    nomeTitulo='Lista de Compras'
                    nomeAdicionar='Adicionar Produto'
                    urlClickBotao='/prodCreate/'
                    funcaoParaSalvar={() => funcaoParaSalvar(dadosDoFormulario.observacao, idProdutodeEnvio, idCompra)}
                    keys={['nome', 'descricao', 'preco']}
                    definicaoDeChavePrimaria='id'
                    limparDadosDoInput={() => limparDadosDoInput()}
                    irParaOutraTela={(objetoRecebido, index=null) => irParaOutraTela(objetoRecebido, index)}
                    linhaSelecionada={linhaSelecionada}
                    funcaoParaRedirecionamento={() => funcaoParaRedirecionamento()}
                    mostrarBotaoAdicionar={null}
                    habilitarEdicao={false}
                    arrayColumns={[]}
                    key={null}
                />
            </HomeAdm>
    );
};

export default IndexFormCompras;
