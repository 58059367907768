// Path: src/components/individual/InputMedio.tsx
import React from 'react';

interface InputMedioProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string|"";
}

const InputMedio = ({ onChange, value, ...restProps }: InputMedioProps) => {
  return (
    <input
      type='text'
      className='nv1'
      onChange={onChange}
      value={value}
      {...restProps}
    />
  );
};

InputMedio.defaultProps = {
    value: '',
    };

export default InputMedio;
