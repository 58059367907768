import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Footer_horizonal from "../agrupamento/Footer_horizonal";
import NavbarHorizontal from "../agrupamento/NavbarHorizontal";
import MainContainer from "../agrupamento/MainContainer";
import Aside from "../agrupamento/Aside";
import Sidebar_menu from "../agrupamento/Sidebar_menu";

interface HomeAdmProps {
    children: React.ReactNode;
}

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const ContentContainer = styled.div`
    flex-grow: 1;
`;

const HomeAdm: React.FC<HomeAdmProps> = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [tipo_visao_container_conteudo_aside, settipo_visao_container_conteudo_aside] = useState("flex");

    const toggleSidebar = () => {
        console.log('toggleSidebar');
        setIsSidebarOpen(!isSidebarOpen);
        setMenuIsOpen(!menuIsOpen);
    };

    const [menuIsOpen, setMenuIsOpen] = useState(true);

    const cor_menu_topo = "#3CB371";
    const corMenuFooter = "#3CB371";
    const corMenuSidebar = "#777777";
    const corMenuAside = "#777777";

    const [widthIsMobile, setwidthIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        console.log(windowWidth);
        if (windowWidth > 450) {
            setIsSidebarOpen(true);
            setwidthIsMobile(false);
            setMenuIsOpen(true);
            settipo_visao_container_conteudo_aside("flex");
        } else {
            setIsSidebarOpen(false);
            setwidthIsMobile(true);
            setMenuIsOpen(false);
            settipo_visao_container_conteudo_aside("block");
        }
    }, [windowWidth]);

    return (
        <LayoutContainer>
            <NavbarHorizontal funcaoAbrirMenu={toggleSidebar} cor_menu_topo={cor_menu_topo} />
            <ContentContainer style={{ display: tipo_visao_container_conteudo_aside }}>
                {isSidebarOpen && <Sidebar_menu corMenuSidebar={corMenuSidebar} widthIsMobile={widthIsMobile} />}
                {!widthIsMobile || !menuIsOpen ? (
                    <>
                        <MainContainer>{children}</MainContainer>
                        <Aside widthIsMobile={widthIsMobile} corMenuAside={corMenuAside} />
                    </>
                ) : null}
            </ContentContainer>
            <Footer_horizonal corMenuFooter={corMenuFooter} />
        </LayoutContainer>
    );
};

export default HomeAdm;
