import React, { useState, useEffect } from 'react';
import { useParams,redirect } from 'react-router-dom';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import FormularioGeneric, { FormData } from '../../../components/agrupamento/FormularioGeneric';

const IndexFormTrad = () => {
    const { idGet } = useParams<{ idGet: string }>();

    const [dadosDoFormulario, setFormData] = useState({
        id: '',
        // traducao_original: '',
        traducao_editavel: ''
    });

    useEffect(() => {
        const funcaoBuscarDadosNoBD = async () => {
            try {
                if (idGet) {
                    const tradData = await api.get(`/administrativo/traducao/tradConsult/${idGet}`);
                    if (tradData && tradData.data && tradData.data.message) {
                        setFormData({
                            id: idGet,
                            // traducao_original: tradData.data.message.trad_original || '',
                            traducao_editavel: tradData.data.message.trad_editavel || ''
                        });
                    }
                }
            } catch (error) {
                console.error('Erro ao obter dados do usuário para edição:', error);
            }
        };

        if (idGet !== "") {
            funcaoBuscarDadosNoBD();
        } else {
            console.log('ID inválido:', idGet);
        }
    }, [idGet]);

    const data: FormData[] = [
        { nomeLabel: 'Id',type:"text", value: dadosDoFormulario.id, sm: 12, md: 6, lg: 12, onChange: (e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...dadosDoFormulario, id: e.target.value }) },
        // { nomeLabel: 'Tradução Original',type:"text", value: dadosDoFormulario.traducao_original, sm: 12, md: 6, lg: 12, onChange: (e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...dadosDoFormulario, traducao_original: e.target.value }) },
        { nomeLabel: 'Tradução Editável',type:"text", value: dadosDoFormulario.traducao_editavel, sm: 12, md: 6, lg: 12, onChange: (e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...dadosDoFormulario, traducao_editavel: e.target.value }) }
    ];

    const GravarDados = async (dados: any) => {
        try {
            const idEnviada:{ id: string } = { id: dados.id };
            const rotaGetTrad = `/administrativo/traducao/tradConsult/${dados.id}`;
            const funcaoBuscarDadosNoBD = await api.get(rotaGetTrad);
            const message = funcaoBuscarDadosNoBD.data.message;

            if (message === null) {
                const endpoint = '/administrativo/traducao/tradCreate';
                const dadosEnvio = {
                    id: dados.id,
                    traducao_editavel: dados.traducao_editavel,
                };

                const result = await api.post(endpoint, dadosEnvio, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Methods': 'POST',
                    },
                });

                alert('Dados inseridos com sucesso!');
                return redirect("/administrativo/traducao/traducaoList");
                // history.push('/traducaoList');
            } else {
                const endpointUpdate = `/administrativo/traducao/tradEdit/${dados.id}`;
                const dadosEnvioUpdate = {
                    trad_id: dados.id,
                    trad_editavel: dados.traducao_editavel
                };

                const resultUpdate = await api.post(endpointUpdate, dadosEnvioUpdate, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Methods': 'POST',
                    },
                });

                alert('Dados atualizados com sucesso!');
                return redirect("administrativo/traducao/traducaoList");
                // history.push('/traducaoList');
            }
        } catch (error) {
            console.error('Erro ao gravar/atualizar dados:', error);
        }
    };

    return (
        <HomeAdm>
            <FormularioGeneric
                dadosDoFormulario={dadosDoFormulario}
                function_onClick={GravarDados}
                setFormData={setFormData}
                dataForm={data}
            />
        </HomeAdm>
    );
};

export default IndexFormTrad;
