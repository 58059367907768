import React, {useState, useEffect, FC} from 'react';
import { redirect, useParams } from 'react-router-dom';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import FormularioGeneric from '../../../components/agrupamento/FormularioGeneric';
import InputMedio from "../../../components/individual/InputMedio";

interface FormData {
    status?: number;
    address: string;
    city: string;
}

interface RouteParams {
    idGet: string;
}

const IndexFormpaciente: React.FC = () => {
    const { idGet } = useParams();
    const [dadosDoFormulario, setFormData] = useState<FormData>({
        address: '',
        city: '',
    });

    useEffect(() => {
        const funcaoBuscarDadosNoBD = async () => {
            try {
                if (idGet) {
                    const response = await api.get('/paciente/' + 'pacienteFormEdit' + '/' + idGet);
                    setFormData(response.data.message);
                }
            } catch (error) {
                console.error('Erro ao obter dados do usuário para edição:', error);
            }
        };

        if (idGet && parseInt(idGet) > 0) {
            funcaoBuscarDadosNoBD();
        } else {
            console.log('ID inválido:', idGet);
        }
    }, [idGet]);



    const GravarDados = async (formData: FormData) => {
        try {
            if (idGet) {
                await api.post('paciente/pacienteEdit/' + idGet, formData, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Methods': 'POST',
                    },
                });
            } else {
                formData.status = 1;
                await api.post('paciente/pacienteCreate', formData, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Methods': 'POST',
                    },
                });
            }
            return redirect("/pacienteList");
            // history.push('/pacienteList');
        } catch (error) {
            console.log(error);
        }
    };
    interface dataFormProps{
        type: "text" | "personalizado" | "number" | "password" | "email" | "url" | "tel" | "search"  | "hidden";
        component?: FC<any>;
        sm?: number|12;
        md?: number|12;
        lg?: number|12;
        nomeLabel?: string|"";
        value?: string|"";
        onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    }

    const data:dataFormProps[] = [
        { nomeLabel: 'address', type:"text", value: dadosDoFormulario.address, sm: 12, md: 6, lg: 12, onChange: (e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...dadosDoFormulario, address: e.target.value }) },
        { nomeLabel: 'city', type:"text", value: dadosDoFormulario.city, sm: 12, md: 6, lg: 12, onChange: (e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...dadosDoFormulario, city: e.target.value }) },
        // {
        //     type: 'personalizado',
        //     value: (
        //         <InputMedio value={dadosDoFormulario.address} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFormData({ ...dadosDoFormulario, address: e.target.value })} />
        //     )
        // },
    ];

    return (
        <HomeAdm>
            <FormularioGeneric
                dadosDoFormulario={dadosDoFormulario}
                function_onClick={GravarDados}
                dataForm={data}
            />
        </HomeAdm>
    );
};

export default IndexFormpaciente;
