import React, {ReactNode} from 'react';
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';

const LabelPerson = styled.div`
    text-align: left;

    .badge {
        background-color: white !important;
        color: #343a40 !important;
    }
`;

interface LabelMedioProps {
    children: ReactNode|any;
    texto: string;
}

const LabelMedio: React.FC<LabelMedioProps> = ({ texto }) => {
    return (
        <LabelPerson>
            <Badge >
                {texto}
            </Badge>
        </LabelPerson>
    );
};

export default LabelMedio;
