import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import TableList from '../../../components/modelo/TableList';

const nomeDasColunas: string[] = [
    'Id',
    'Tradução Editável',
];

const IndexTradList: React.FC = () => {
    const [trad, setTrad] = useState<any[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.get("/administrativo/traducao/tradList", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Methods": "GET",
                },
            });
            setTrad(response.data);
        } catch (error) {
            console.error('Erro ao obter dados:', error);
        }
    };

    const funcaoDesativar = async (tradData: any) => {
        const proceed = window.confirm("Tem certeza de que deseja desativar este registro ?");
        if (proceed) {
            try {
                const result = await api.post(
                    "/administrativo/traducao/tradDisabled/" + tradData.id,
                    null,
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Methods": "POST",
                        },
                    }
                );
                // alert('Desativado com sucesso!');
                fetchData(); // Chama a função para buscar dados novamente
            } catch (error) {
                console.error('Erro ao desativar :', error);
            }
            console.log("Desativar usuário:", tradData);
        }
    };

    return (
        <HomeAdm>
            <TableList
                funcaoDesativar={(dados) => funcaoDesativar(dados)}
                nomeDasColunas={nomeDasColunas}
                urlClickBotao={'/administrativo/traducao/traducaoCreate'}
                dadosVindosBackEnd={trad}
                habilitarColunaDeAcao={true}
                habilitarExclusao={false}
                linkParaEdicao='/administrativo/traducao/traducaoEdit/'
                keys={['trad_id', 'trad_editavel']}
                definicaoDeChavePrimaria='trad_id'
             arrayColumns={['Código','Descrição']}/>
        </HomeAdm>
    );
};

export default IndexTradList;
