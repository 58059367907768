import React, { ReactNode } from 'react';

interface TableHeadProps {
    children: ReactNode;
}

const TableHead: React.FC<TableHeadProps> = (props) => {
    return (
        <thead>
        {props.children}
        </thead>
    );
};

export default TableHead;
