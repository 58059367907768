import React, { ReactNode, MouseEventHandler } from 'react';

interface TableDataProps {
    children: ReactNode;
    onClick?: MouseEventHandler<HTMLTableDataCellElement>;
}

const TableData: React.FC<TableDataProps> = ({ children, onClick }) => {
    return (
        <td onClick={onClick}>{children}</td>
    );
};

export default TableData;
