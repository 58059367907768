// components/Navbar.tsx
import React from 'react';
import styled from 'styled-components';

const SidebarLink = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    padding: 10px;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #333;
        color: #ffffff;
    }
`;

const Icon = styled.div`
    margin-bottom: 5px;  // Ajuste o espaçamento conforme necessário
`;

type LinkDarkProps = {
    url: string;
    icon: React.ReactNode;
    texto: string;
};

const LinkDark: React.FC<LinkDarkProps> = (props) => {
    return (
        <SidebarLink href={props.url}>
            <Icon>{props.icon}</Icon>
            {props.texto}
        </SidebarLink>
    );
};

export default LinkDark;
