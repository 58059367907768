// api.ts
import axios, { AxiosInstance } from 'axios';

const api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API
});
console.log(process.env.REACT_APP_API);

// Adicione um interceptador para adicionar o token JWT a todas as solicitações
api.interceptors.request.use(
    config => {
        // Obtenha o token JWT armazenado localmente após o login
        const token = localStorage.getItem('token');
        // console.log(token);

        // Se houver um token, adicione-o ao cabeçalho de autorização
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);



export default api;
