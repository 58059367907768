import React, {Suspense, useEffect, useState} from 'react';
import {
    BrowserRouter as BrowserRouterWithBasename,
    Route,
    Routes,
    Navigate,
    useNavigate,
    NavigateFunction
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

// Importe seus componentes aqui
import IndexTableList from "../Pagina/Administrativo/pessoa/IndexTableList";
import IndexFormPessoa from "../Pagina/Administrativo/pessoa/indexFormPessoa";
import IndexProdutoList from "../Pagina/Administrativo/produto/IndexProdutoList";
import IndexFormProduto from "../Pagina/Administrativo/produto/IndexFormProduto";
import IndexFormCompras from "../Pagina/Administrativo/compras/IndexFormCompras";
import ListaDeItensCompras from "../Pagina/Administrativo/compras/ListaDeItensCompras";
import IndexListaCompras from "../Pagina/Administrativo/compras/IndexListaCompras";
import IndexConfigList from "../Pagina/Administrativo/configuracao/IndexConfigList";
import IndexFormConfig from "../Pagina/Administrativo/configuracao/IndexFormConfig";
import IndexTradList from "../Pagina/Administrativo/traducao/IndexTradList";
import IndexFormTrad from "../Pagina/Administrativo/traducao/indexFormTrad";
import IndexPublicacaoList from "../Pagina/Administrativo/publicacao/IndexPublicacaoList";
import IndexFormPublicacao from "../Pagina/Administrativo/publicacao/IndexFormPublicacao";
import IndexMostrarTabelasList from "../Pagina/Administrativo/extras/mostrarTabelas/IndexMostrarTabelasList";
import IndexMostraViewList from "../Pagina/Administrativo/extras/mostrarTabelas/IndexMostraViewList";
import IndexFormGenerateModelo from "../Pagina/Administrativo/extras/mostrarTabelas/IndexFormGenerateModelo";
import IndexFormPaciente from "../Pagina/Administrativo/Paciente/IndexFormPaciente";
import IndexPacienteList from "../Pagina/Administrativo/Paciente/IndexPacienteList";
import LoginIndex from "../Pagina/Administrativo/login/LoginIndex";
import RegisterIndex from "../Pagina/Administrativo/login/RegisterIndex";
import api from "../services/api";

const Route_pessoa: React.FC = () => {
        return (
            <Routes>
                <Route path="/pessoaList" element={<IndexTableList />} />
                <Route path="/pessoaCreate" element={<IndexFormPessoa />} />
                <Route path="/pessoaEdit/:idGet" element={<IndexFormPessoa />} />
            </Routes>
        );
}

const Route_produto: React.FC = () => {
    return (
        <Routes>
            <Route path="/prodList" element={<IndexProdutoList />} />
            <Route path="/prodCreate" element={<IndexFormProduto />} />
            <Route path="/prodEdit/:idGet" element={<IndexFormProduto />} />
        </Routes>
    );
}

//compra,config,traducao,publicacao,paciente,extra,login,cadastrar

const Route_compra: React.FC = () => {
    return (
        <Routes>
            <Route path="/compraList" element={<IndexFormCompras />} />
            <Route path="/ListaItens/:idGet" element={<ListaDeItensCompras />} />
            <Route path="/compraList/:idGet" element={<IndexFormCompras />} />
            <Route path="/ListaCompras" element={<ListaDeItensCompras />} />
            <Route path="/ListaComprasSelect" element={<IndexListaCompras />} />
        </Routes>
    );
}

const Route_config: React.FC = () => {
    return (
        <Routes>
            <Route path="/configuracaoList" element={<IndexConfigList />} />
            <Route path="/configuracaoCreate" element={<IndexFormConfig />} />
            <Route path="/configuracaoEdit/:idGet" element={<IndexFormConfig />} />
        </Routes>
    );
}

const Route_traducao: React.FC = () => {
    return (
        <Routes>
            <Route path="/traducaoList" element={<IndexTradList />} />
            <Route path="/traducaoCreate" element={<IndexFormTrad />} />
            <Route path="/traducaoEdit/:idGet" element={<IndexFormTrad />} />
        </Routes>
    );
}

const Route_publicacao: React.FC = () => {
    return (
        <Routes>
            <Route path="/publicacaoList" element={<IndexPublicacaoList />} />
            <Route path="/publicacaoCreate" element={<IndexFormPublicacao />} />
            <Route path="/publicacaoEdit/:idGet" element={<IndexFormPublicacao />} />
        </Routes>
    );
}

const Route_paciente: React.FC = () => {
    return (
        <Routes>
            <Route path='/pacienteList' element={<IndexPacienteList />} />
            <Route path='/pacienteCreate' element={<IndexFormPaciente />} />
            <Route path='/pacienteEdit/:idGet' element={<IndexFormPaciente />} />
        </Routes>
    );
}

const Route_extra: React.FC = () => {
    return (
        <Routes>
            <Route path="/showtables" element={<IndexMostrarTabelasList />} />
            <Route path="/showtable/:nome_tabela" element={<IndexMostraViewList />} />
            <Route path="/gerarModelo" element={<IndexFormGenerateModelo />} />
        </Routes>
    );
}






const RouteAdministrativo: React.FC = () => {
    const navigate = useNavigate();

    const [isAuthenticated, setIsAuthenticated] = useState<string | null>(getToken());
    useEffect(() => {
        // Verifica o token ativo ao montar o componente
        verificarTokenAtivo();
    }, []);

    function getToken() {
        console.log(localStorage.getItem('token'));
        return localStorage.getItem('token');
    }

    function verificarTokenAtivo() {
        api.get('/api/me', {
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        }).then((response) => {
            console.log(response.data);
            localStorage.setItem("nivel", response.data.nivel);
        }).catch((error) => {
            localStorage.removeItem('token');
            localStorage.removeItem('nivel');
            navigate('/administrativo/login');
        });
    }



    // Redirecione para a página de login se o usuário não estiver autenticado
    if (isAuthenticated === null) {
        return <Navigate to="/administrativo/login" />;
    }
    return (
        <>
            <Routes >
                    {/*<Routes>*/}
                    <Route path="/pessoa/*" element={<Route_pessoa />} />
                    <Route path="/produto/*" element={<Route_produto />} />
                    <Route path="/compra/*" element={<Route_compra />} />
                    <Route path="/config/*" element={<Route_config />} />
                    <Route path="/traducao/*" element={<Route_traducao />} />
                    <Route path="/publicacao/*" element={<Route_publicacao />} />
                    <Route path="/paciente/*" element={<Route_paciente />} />
                    <Route path="/extra/*" element={<Route_extra />} />
            </Routes>
        </>
    );
};

export default RouteAdministrativo;
