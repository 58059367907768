import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import InputMedio from '../individual/InputMedio';
import LabelMedio from '../individual/LabelMedio';
import DivForInput from '../individual/DivForInput';

const InputGeneration = (props) => {
   const { Fields } = props;

    return (
        <>
            {Fields.map((field, index) => (
                <Col key={index} sm={field.sm} md={field.md} lg={field.lg}>
                    <DivForInput>
                        <LabelMedio texto={field.nome}></LabelMedio>
                        <InputMedio value={field.value} onChange = {(e)=>field.onChange(e)}></InputMedio>
                    </DivForInput>
                </Col>
            ))}
        </>
    );
};

export default InputGeneration;
