import React, { ReactNode } from 'react';
import TableRow from '../individual/TableRow';
import TableCell from '../individual/TableCell';
import TableDetails from '../agrupamento/TableDetails';
import CabecalhoBasico from '../squad/CabecalhoBasico';

interface TableListProps {
  arrayColumns: string[];
  nomeDasColunas: string[];
  dadosVindosBackEnd: any[];
  urlClickBotao: string;
  nomeAdicionar?: string;
  nomeTitulo?: string;
  funcaoDesativar?: (data?:any) => void;
  habilitarColunaDeAcao?: boolean;
  habilitarExclusao?: boolean;
  habilitarEdicao?: boolean;
  // funcaoBuscarDadosNoBD?: () => void;
  keys: string[];
  linkParaEdicao?: string;
  definicaoDeChavePrimaria: string;
  mostrarBotaoAdicionar?: boolean;
}

const TableList: React.FC<TableListProps> = ({
                                               nomeDasColunas,
                                               dadosVindosBackEnd,
                                               urlClickBotao = "/",
                                               nomeAdicionar = "Adicionar",
                                               nomeTitulo = "Lista",
                                               funcaoDesativar,
                                               habilitarColunaDeAcao,
                                               habilitarExclusao,
                                               habilitarEdicao = true,
                                               // funcaoBuscarDadosNoBD,
                                               keys,
                                               linkParaEdicao,
                                               definicaoDeChavePrimaria,
                                               mostrarBotaoAdicionar = true,
                                                arrayColumns
                                             }) => (
    <>
      <CabecalhoBasico
          urlClickBotao={urlClickBotao}
          nomeAdicionar={nomeAdicionar}
          nomeTitulo={nomeTitulo}
          mostrarBotaoAdicionar={mostrarBotaoAdicionar}
      />
      <TableDetails
          keys={keys}
          arrayColumns={arrayColumns}
          linkParaEdicao={linkParaEdicao}
          nomeDasColunas={nomeDasColunas}
          dadosVindosBackEnd={dadosVindosBackEnd}
          funcaoDesativar={funcaoDesativar}
          // funcaoBuscarDadosNoBD={funcaoBuscarDadosNoBD}
          habilitarColunaDeAcao={habilitarColunaDeAcao}
          habilitarExclusao={habilitarExclusao}
          habilitarEdicao={habilitarEdicao}
          definicaoDeChavePrimaria={definicaoDeChavePrimaria}
          onClickLinhaTabela={() => null}
      >
        {dadosVindosBackEnd.length>0 ? (
            dadosVindosBackEnd.map((data, index) => (
                <TableRow key={index}>
                  <TableCell data={data} keys={nomeDasColunas} />
                </TableRow>
            ))
        ) : (
            <TableRow>
              <TableCell key={null} keys={[]} data={[]}  colSpan={nomeDasColunas.length} align="center"></TableCell>
            </TableRow>
        )}
      </TableDetails>
    </>
);

export default TableList;
