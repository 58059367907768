import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import TableList from '../../../components/modelo/TableList';

interface Produto {
    id: number;
    nome: string;
    descricao: string;
    preco: number;
}

const nomeDasColunas: string[] = [
    'Nome',
    'Descrição',
    'Preço',
];

const fetchData = async (setData: React.Dispatch<React.SetStateAction<Produto[]>>) => {
    try {
        const response = await api.get<Produto[]>("/produto/prodList", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET",
            },
        });
        setData(response.data);
    } catch (error) {
        console.error('Erro ao obter dados:', error);
    }
};

const IndexProdutoList: React.FC = () => {
    const [produto, setProdutos] = useState<Produto[]>([]);

    useEffect(() => {
        fetchData(setProdutos);
    }, []);

    const funcaoDesativar = async (userData: Produto) => {
        const proceed = window.confirm("Tem certeza de que deseja desativar este usuário?");
        if (proceed) {
            try {
                const result = await api.post(
                    "/produto/prodDisabled/" + userData.id,
                    {},
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Methods": "POST",
                        },
                    }
                );
                fetchData(setProdutos); // Chama a função para buscar dados novamente
            } catch (error) {
                console.error('Erro ao desativar :', error);
            }
            console.log("Desativar usuário:", userData);
        }
    };

    return (
        <HomeAdm>
            <TableList
                nomeDasColunas={nomeDasColunas}
                dadosVindosBackEnd={produto}
                habilitarColunaDeAcao={true}
                habilitarExclusao={true}
                funcaoDesativar={(dados) => funcaoDesativar(dados)}
                linkParaEdicao = '/prodEdit/'
                nomeTitulo = 'Lista de Produtos'
                nomeAdicionar = 'Adicionar Produto'
                urlClickBotao='/prodCreate/'
                keys={['nome','descricao', 'preco']}
                definicaoDeChavePrimaria='id'
                arrayColumns={[]}
                />
        </HomeAdm>
    );
};

export default IndexProdutoList;
