import React, {useState, useEffect, FC} from 'react';
import { redirect, useParams } from 'react-router-dom';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import FormularioGeneric from '../../../components/agrupamento/FormularioGeneric';

const IndexFormPessoa = () => {

    const { idGet } = useParams();

    const [dadosDoFormulario, setFormData] = useState({
        id: 0, // Alteração aqui
        nome: '',
        sobrenome: '',
        telefone: '',
        documento: '',
        email: '',
        instagram: '',
        facebook: '',
        observacao: '',
    });

    useEffect(() => {
        const funcaoBuscarDadosNoBD = async () => {
            try {
                if (idGet && parseInt(idGet) > 0) { // Verificação aqui
                    const pessoaData = await api.get(`/pessoa/pessoaFormEdit/${idGet}`);

                    if (pessoaData && pessoaData.data && pessoaData.data.message) {
                        setFormData({
                            id: parseInt(idGet),
                            nome: pessoaData.data.message.nome || '',
                            sobrenome: pessoaData.data.message.sobrenome || '',
                            telefone: pessoaData.data.message.telefone || '',
                            documento: pessoaData.data.message.documento || '',
                            email: pessoaData.data.message.email || '',
                            instagram: pessoaData.data.message.instagram || '',
                            facebook: pessoaData.data.message.facebook || '',
                            observacao: pessoaData.data.message.observacao || '',
                        });
                    }
                } else {
                    console.log('ID inválido:', idGet);
                }
            } catch (error) {
                console.error('Erro ao obter dados do usuário para edição:', error);
            }
        };

        debugger;
        if (idGet && parseInt(idGet) > 0) {
            funcaoBuscarDadosNoBD();
        } else {
            console.log('ID inválido:', idGet);
        }
    }, [idGet]);

     interface FormData {
        type: "text" | "personalizado" | "number" | "password" | "email" | "url" | "tel" | "search" | "hidden";
        component?: FC<any>;
        sm?: number | 12;
        md?: number | 12;
        lg?: number | 12;
        nomeLabel?: string | "";
        value?: string | "";
        onChange?: (e: any) => void;
    }

    const data:FormData[] = [
        { nomeLabel: 'Nome',  type:"text", value: dadosDoFormulario.nome, sm: 12, md: 6, lg: 12, onChange: (e:any) => setFormData({ ...dadosDoFormulario, nome: e.target.value }) },
        { nomeLabel: 'Sobrenome',type:"text", value: dadosDoFormulario.sobrenome, sm: 12, md: 6, lg: 12, onChange: (e:any) => setFormData({ ...dadosDoFormulario, sobrenome: e.target.value }) },
        { nomeLabel: 'Celular',type:"text", value: dadosDoFormulario.telefone, sm: 12, md: 6, lg: 12, onChange: (e:any) => setFormData({ ...dadosDoFormulario, telefone: e.target.value }) },
        { nomeLabel: 'Documento',type:"text", value: dadosDoFormulario.documento, sm: 12, md: 6, lg: 12, onChange: (e:any) => setFormData({ ...dadosDoFormulario, documento: e.target.value }) },
        { nomeLabel: 'E-mail',type:"text", value: dadosDoFormulario.email, sm: 12, md: 6, lg: 12, onChange: (e:any) => setFormData({ ...dadosDoFormulario, email: e.target.value }) },
        { nomeLabel: 'Instagram',type:"text", value: dadosDoFormulario.instagram, sm: 12, md: 6, lg: 12, onChange: (e:any) => setFormData({ ...dadosDoFormulario, instagram: e.target.value }) },
        { nomeLabel: 'Facebook',type:"text", value: dadosDoFormulario.facebook, sm: 12, md: 6, lg: 12, onChange: (e:any) => setFormData({ ...dadosDoFormulario, facebook: e.target.value }) },
        { nomeLabel: 'Observação',type:"text", value: dadosDoFormulario.observacao, sm: 12, md: 6, lg: 12, onChange: (e:any) => setFormData({ ...dadosDoFormulario, observacao: e.target.value }) },
    ];


    interface dadosEnvioProps {
        id?:number|0;
        nome: string;
        sobrenome: string;
        telefone: string;
        documento: string;
        instagram: string;
        facebook: string;
        email: string;
        observacao: string;
    }

    interface dadosProps {
        id:number|0;
        nome: string;
        sobrenome: string;
        telefone: string;
        documento: string;
        instagram: string;
        facebook: string;
        email: string;
        observacao: string;
    }

    const GravarDados = async (dados:dadosProps) => {
        try {
            const endpoint = dados.id > 0 ? `/pessoa/pessoaEdit/${dados.id}` : '/pessoa/pessoaCreate';

            const dadosEnvio:dadosEnvioProps = {
                nome: dados.nome,
                sobrenome: dados.sobrenome,
                telefone: dados.telefone,
                documento: dados.documento,
                instagram: dados.instagram,
                facebook: dados.facebook,
                email: dados.email,
                observacao: dados.observacao,
            };

            if (dados.id > 0) {
                dadosEnvio.id = dados.id;
            }

            const result = await api.post(endpoint, dadosEnvio, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Methods': 'POST',
                },
            });

            alert(dados.id > 0 ? 'Dados atualizados com sucesso!' : 'Dados inseridos com sucesso!');
            return redirect('/pessoaList')
            // history.push('/pessoaList');
        } catch (error) {
            console.error('Erro ao gravar/atualizar dados:', error);
        }
    };

debugger;
    return (
        <HomeAdm>
            <FormularioGeneric
                dadosDoFormulario={dadosDoFormulario}
                function_onClick={GravarDados}
                setFormData={setFormData}
                dataForm={data}
            />
        </HomeAdm>
    );
};

export default IndexFormPessoa;
