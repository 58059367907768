// import React,{ Suspense, lazy } from 'react';
import React,{ Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    BrowserRouter as Router,Routes,
    Route
} from 'react-router-dom';
// import Layout_adm from "../Pagina/Administrativo/layout_adm";
import RouteAdministrativo from "../Route/RouteAdministrativo";
import IndexTableList from "../Pagina/Administrativo/pessoa/IndexTableList";
import LoginIndex from "../Pagina/Administrativo/login/LoginIndex";
import RegisterIndex from "../Pagina/Administrativo/login/RegisterIndex";
// import RouteAdministrativo from "../Route/Administrativo";



// const Lovevip2021 = lazy(() => import('./Aframejs/LoveVip2021'));
// const Home = lazy(() => import('./Home'));
// const Round6 = lazy(() => import('./Round6'));
// const Vcard = lazy(() => import('./Vcard'));
// const Modelo1 = lazy(() => import('./Template/modelo1'));
// const PagarEmBtc = lazy(() => import('../api/Btcpay/pagamentoBtcPay').then(e=>{
//     e.BTCPAY()
// }));

const App =()=> {
    return(<div>
        <Router  basename='/'>
            <Suspense fallback={<div/>}>
                <Routes>
                    <Route path="/administrativo/*" element={<RouteAdministrativo />} />
                    <Route path="/administrativo/login" element={<LoginIndex />} />
                    <Route path="/administrativo/cadastrar" element={<RegisterIndex />} />
                    {/*<RouteAdministrativo/>*/}
                    {/*<Route  path="/config/configuracaoList"  element={<IndexTableList/>}/>*/}
            {/*<Switch>*/}
                {/*<Route exact path="*"  component={()=><Home/>}/>*/}

                {/*<Route exact path="/"  component={()=><Home/>} />*/}

                {/*<Route exact path="/vcard"  component={()=><Vcard/>} />*/}
                {/*<Route exact path="/lovevip2021/:nomeInstagram" component={()=><Lovevip2021/>} />*/}
                {/*<Route exact path="/pagarembtc" component={()=><PagarEmBtc/>} />*/}
                {/*<Route exact path="/round6" component={()=><Round6/>} />*/}
                {/*<Route exact path="/modelo1" component={()=><Modelo1/>} />*/}
                {/*<Route exact path="/layout_adm" component={()=><Layout_adm/>} />*/}
                {/*<Route exact path="/layout_user" component={()=><Layout_adm/>} />*/}


            {/*</Switch>*/}
                </Routes>
            </Suspense>
            {/*<Routes   children={} />*/}
        </Router>
    </div>
    );
};

export default App;

if (document.getElementById('App')) {
    ReactDOM.render(<App />, document.getElementById('App'));
}
