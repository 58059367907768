import React from 'react';
// import styled from 'styled-components';

interface Props {
    children: React.ReactNode;
    funcaoAbrirMenu: () => void;
    style?: React.CSSProperties;
}


const Icon = ({children,funcaoAbrirMenu,style}:Props) => {

    return (
            <div onClick = {funcaoAbrirMenu} style={style}>{children}</div>
    );
};

export default Icon;
