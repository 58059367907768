import React from 'react';
// import PropTypes from 'prop-types';

interface Props {
    children: React.ReactNode;
}
const DivForInput = ({children}:Props) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>{children}</div>
    );
};

export default DivForInput;