import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import TableList from '../../../components/modelo/TableList';

const nomeDasColunas: string[] = [
    'Título',
    'Subtítulo',
    'URL da Imagem',
    'Conteúdo',
];

const IndexPublicacaoList: React.FC = () => {
    const [publicacao, setPublicacao] = useState<any[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.get("/publicacao/publicacaoList", {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Methods": "GET",
                },
            });
            setPublicacao(response.data);
        } catch (error) {
            console.error('Erro ao obter dados:', error);
        }
    };

    const funcaoDesativar = async (publiData: any) => {
        const proceed = window.confirm("Tem certeza de que deseja desativar este usuário?");
        if (proceed) {
            try {
                const result = await api.post(
                    "/publicacao/publicacaoDisabled/" + publiData.id,
                    null,
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Methods": "POST",
                        },
                    }
                );
                // alert('Desativado com sucesso!');
                fetchData(); // Chama a função para buscar dados novamente
            } catch (error) {
                console.error('Erro ao desativar :', error);
            }
            console.log("Desativar usuário:", publiData);
        }
    };

    return (
        <HomeAdm>
            <TableList
                funcaoDesativar={funcaoDesativar}
                nomeDasColunas={nomeDasColunas}
                dadosVindosBackEnd={publicacao}
                habilitarColunaDeAcao={true}
                habilitarExclusao={true}
                linkParaEdicao='/publicacaoEdit/'
                keys={['titulo', 'subtitulo', 'url_imagem', 'conteudo']}
                definicaoDeChavePrimaria='id'
             arrayColumns={[]}
             urlClickBotao={"/administrativo/publicacao/publicacaoCreate"}/>
        </HomeAdm>
    );
};

export default IndexPublicacaoList;
