import React, { useState } from 'react';
import styled from 'styled-components';
import LinkDark from "../individual/LinkDark";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faShoppingCart,
    faGlobe,
    faPaperPlane,
    faPerson,
    faGears,
    faShoppingBag,
    faList,
    faChartBar,
    faChevronDown,
    faFileAlt
} from '@fortawesome/free-solid-svg-icons';

const SidebarContainer = styled.section<{ cormenusidebar: string; widthIsMobile: boolean }>`
    background: linear-gradient(to top, ${(props) => props.cormenusidebar}, #888888);
    width: ${(props) => (props.widthIsMobile ? '100%' : '150px')};
    height: ${(props) => (props.widthIsMobile ? '90vh' : 'auto')};
    min-width: 150px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    transition: width 2s ease;
    box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.1);
`;


const Dropdown = styled.div`
    position: relative;
    display: inline-block;
    padding: 10px;
`;

const DropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: #777777;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
`;

const Option = styled.a`
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    &:hover {
        //background-color: #f1f1f1;
    }
`;

const ExtrasText = styled.span`
    margin-right: 5px;
    text-align: center;
    color: #ffffff;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
    margin-left: 5px;
    cursor: pointer;
`;

const DropdownMenu: React.FC = () => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    return (
        <Dropdown>
            <div style={{ cursor: "pointer", textAlign: "center" }} onClick={toggleDropdown}>
                <ExtrasText>Extras</ExtrasText>
                <ArrowIcon  icon={faChevronDown} />
            </div>
            {isDropdownOpen && (
                <DropdownContent>
                    <Option>
                        <LinkDark url={"/administracao/extras/showtables"} icon={<FontAwesomeIcon icon={faList} />} texto={"Ver Tabelas"} />
                    </Option>
                    <Option>
                        <LinkDark url={"/administracao/extras/gerarModelo"} icon={<FontAwesomeIcon icon={faList} />} texto={"Gerador"} />
                    </Option>
                </DropdownContent>
            )}
        </Dropdown>
    );
};

interface SidebarProps {
    corMenuSidebar: string;
    widthIsMobile: boolean;
}


const SidebarMenu: React.FC<SidebarProps> = (props) => {
    const nivel =localStorage.getItem('nivel');
    // console.log(props);
    return (
        <SidebarContainer  cormenusidebar={props.corMenuSidebar} widthIsMobile={props.widthIsMobile}>
            <LinkDark url={"/administrativo/pessoa/pessoaList"} icon={<FontAwesomeIcon icon={faPerson} />} texto={"Pessoas"} />
            <LinkDark url={"/administrativo/traducao/traducaoList"} icon={<FontAwesomeIcon icon={faGlobe} />} texto={"Tradução"} />

            {nivel !== null && parseInt(nivel) === 1 && (
                <>
                    <LinkDark url={"/administrativo/config/configuracaoList"}  icon={<FontAwesomeIcon icon={faGears} />} texto={"Configurações"} />
                    <LinkDark url={"/administrativo/publicacao/publicacaoList"} icon={<FontAwesomeIcon icon={faPaperPlane} />} texto={"Publicações"} />
                    <LinkDark url={"/administrativo/produto/prodList"} icon={<FontAwesomeIcon icon={faShoppingBag} />} texto={"Produtos"} />
                    <LinkDark url={"/administrativo/compra/compraList"} icon={<FontAwesomeIcon icon={faShoppingCart} />} texto={"Vendas"} />
                    <LinkDark url={"/administrativo/compra/ListaComprasSelect"} icon={<FontAwesomeIcon icon={faList} />} texto={"Lista de Compras"} />
                    <LinkDark url={"/administrativo/paciente/pacienteList"} icon={<FontAwesomeIcon icon={faList} />} texto={"Pacientes"} />
                    {/*<LinkDark url={"/administrativo/logout"} icon={<FontAwesomeIcon icon={faClo} />} texto={"Sair"} />*/}
                    <DropdownMenu />
                </>
            )}

        </SidebarContainer>
    );
};

export default SidebarMenu;
