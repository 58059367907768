import React, { useState } from "react";
import axios from "axios";
import {Link, Navigate} from "react-router-dom";
import styled from "styled-components";
import api from "../../../services/api";

const LoginContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Login_form_container = styled.div`
    width: 900px;
    height: 500px;
    display: flex;
    border-radius: 10px;
`;

const Left_alterado = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
`;

const Form_container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const H1_altererado = styled.h1`
    font-size: 40px;
    margin-top: 0;
`;

const Input_alterado = styled.input`
    outline: none;
    border: none;
    width: 370px;
    padding: 15px;
    border-radius: 10px;
    background-color: #edf5f3;
    margin: 5px 0;
    font-size: 14px;
`;

const Error_msg = styled.div`
    width: 370px;
    padding: 15px;
    margin: 5px 0;
    font-size: 14px;
    background-color: #f34646;
    color: white;
    border-radius: 5px;
    text-align: center;
`;

const Right_div = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #3bb19b;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
`;

const StyledH1 = styled.h1`
    margin-top: 0;
    color: white;
    font-size: 40px;
    align-self: center;
`;

const White_btn = styled.button`
    border: none;
    outline: none;
    padding: 12px 0;
    background-color: white;
    border-radius: 20px;
    width: 180px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
`;

const Green_btn = styled.button`
    background-color: #3bb19b;
    color: white;
    margin: 10px;
`;

interface LoginData {
    email: string;
    password: string;
}

const LoginIndexLayout: React.FC = () => {
    const [data, setData] = useState<LoginData>({ email: "", password: "" });
    const [error, setError] = useState<string>("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    function getToken() {
        console.log(localStorage.getItem('token'));
        return localStorage.getItem('token');
    }

    const [isAuthenticated, setIsAuthenticated] = useState<string | null>(getToken());



    // useEffect(() => {
    //     // Verificar se o usuário está autenticado
    //     const token = getToken();
    //     setIsAuthenticated(token); // Define isAuthenticated com base na existência do token
    // }, []);

    // Redirecione para a página de login se o usuário não estiver autenticado
    if (isAuthenticated !== null) {
        return <Navigate to="/administrativo/pessoa/pessoaList" />;
    }

    const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            console.log("login");
            const url = "/api/login";
            const { data: res } = await api.post(url, {"email":data.email,"password":data.password}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });


            localStorage.setItem("token", res.access_token);
            localStorage.setItem("nivel", res.user.nivel);

            window.location.href = "/administrativo/pessoa/pessoaList";
            // window.location.href = "/administrativo/login";
        } catch (error) {

        }
    };

    return (
        <LoginContainer>
            <Login_form_container>
                <Left_alterado>
                    <Form_container>
                        <H1_altererado>Login</H1_altererado>
                        <Input_alterado
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            value={data.email}
                            required
                        />
                        <Input_alterado
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                            value={data.password}
                            required
                        />
                        {error && <Error_msg>{error}</Error_msg>}
                        <Green_btn type="submit" onClick={handleSubmit}>
                            Entrar
                        </Green_btn>
                    </Form_container>
                </Left_alterado>
                <Right_div>
                    <StyledH1>Novo Aqui ?</StyledH1>
                    <Link to="/cadastrar">
                        <White_btn>Cadastre-se</White_btn>
                    </Link>
                </Right_div>
            </Login_form_container>
        </LoginContainer>
    );
};

export default LoginIndexLayout;
