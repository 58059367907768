import React, {ChangeEvent, FormEvent, useState} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import styled, { keyframes } from "styled-components";

const Signup_container = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Signup_form_container = styled.div`
    width: 900px;
    height: 500px;
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
`;

const Styled_h1 = styled.h1`
    font-size: 40px;
    margin-top: 0;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    text-transform: capitalize;
    text-overflow: clip;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    font-style: italic;
    animation: animate 3s ease-in-out infinite;
`;

const Animate = keyframes`
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(166, 180, 58);
    background: linear-gradient(
            90deg,
            rgba(166, 180, 58, 1) 0%,
            rgba(223, 253, 29, 1) 47%,
            rgba(252, 176, 69, 1) 100%
    );
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
`;

const Lefth1_styled = styled.h1`
    margin-top: 0;
    color: white;
    font-size: 35px;
    align-self: center;
`;

const Right = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
`;

const Form_container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Form_container_h1 = styled.h1`
    font-size: 40px;
    margin-top: 0;
`;

const Input_editado = styled.input`
    outline: none;
    border: none;
    width: 370px;
    padding: 15px;
    border-radius: 10px;
    background-color: #edf5f3;
    margin: 5px 0;
    font-size: 14px;
`;
const Error_msg = styled.div`
    width: 370px;
    padding: 15px;
    margin: 5px 0;
    font-size: 14px;
    background-color: #f34646;
    color: white;
    border-radius: 5px;
    text-align: center;
`;

const White_btn = styled.button`
    border: none;
    outline: none;
    padding: 12px 0;
    background-color: white;
    border-radius: 20px;
    width: 180px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
`;

const Green_btn = styled.button`
    background-color: #035243;
    color: white;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin: 10px;
`;


//
// .green_btn:hover {
//     background-color: #0d0e0d;
//     color: white;
// }
//
// @media screen and (max-width: 578px) {
// .signup_form_container {
//         width: 100%;
//         height: 100%;
//         border-radius: 0;
//     }
// .left {
//         display: none;
//     }
// .right {
//         border-top-right-radius: 0;
//         border-bottom-right-radius: 0;
//     }
// .form_container {
//         width: 100%;
//     }
// .input {
//         width: 100%;
//     }
// .error_msg {
//         width: 100%;
//     }
// .green_btn {
//         width: 100%;
//     }
// .green_btn, .white_btn {
//         margin: 10px 0;
//         width: 150px;
//         height: 45px;
//     }
//
// }



interface UserData {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

const RegisterIndexLayout: React.FC = () => {
    const [data, setData] = useState<UserData>({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    });
    const [error, setError] = useState("");

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const url = "http://localhost:9000/api/users";
            const { data: res } = await axios.post(url, data);
            window.location.href = "/login";
            console.log(res.message);
        } catch (error) {

        }
    };

    return (
        <Signup_container>
            <Signup_form_container>
                <Left>
                    <Lefth1_styled>Bem vindo</Lefth1_styled>
                    <Link to="/login">
                        <White_btn type="button">fazer login?</White_btn>
                    </Link>
                </Left>
                <Right>
                    <Form_container onSubmit={handleSubmit}>
                        <Styled_h1>Criar Conta</Styled_h1>
                        <Input_editado
                            type="text"
                            placeholder="Nome"
                            name="firstName"
                            onChange={handleChange}
                            value={data.firstName}
                            required
                        />
                        <Input_editado
                            type="text"
                            placeholder="Sobre Nome"
                            name="lastName"
                            onChange={handleChange}
                            value={data.lastName}
                            required
                        />
                        <Input_editado
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            value={data.email}
                            required
                        />
                        <Input_editado
                            type="password"
                            placeholder="Senha"
                            name="password"
                            onChange={handleChange}
                            value={data.password}
                            required
                        />
                        {error && <Error_msg>{error}</Error_msg>}
                        <Green_btn type="submit">Cadastrar</Green_btn>
                    </Form_container>
                </Right>
            </Signup_form_container>
        </Signup_container>
    );
};

export default RegisterIndexLayout;