import React from 'react';
import styled from 'styled-components';
import Logo from "../individual/Logo";
import Icon from "../individual/Icon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import api from "../../services/api"; // Importe o ícone de logout
import {useNavigate} from 'react-router-dom';

interface NavbarProps {
    cor_menu_topo: string;
    funcaoAbrirMenu: () => void;
}

const NavbarContainer = styled.div<{ cor_menu_topo: string }>`
    background: linear-gradient(to bottom right, ${(props) => props.cor_menu_topo}, #008000);
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;



const NavbarHorizontal: React.FC<NavbarProps> = (props) => {

    const navigate = useNavigate();
    const HandleLogout = async () => {
        try {
            // Envie uma solicitação para a API para efetuar o logout
            await api.post('/api/logout');

            // Limpe o token JWT armazenado localmente após o logout
            localStorage.removeItem('token');
            localStorage.removeItem('nivel');

            // Redirecione para a página de login ou qualquer outra página desejada

            navigate('/administrativo/login');
        } catch (error) {
            console.error('Erro ao fazer logout:', error);
            // Trate o erro, se necessário
        }
    };

    return (
        <NavbarContainer cor_menu_topo={props.cor_menu_topo}>
            <Icon funcaoAbrirMenu={() => props.funcaoAbrirMenu()}>
                <FontAwesomeIcon icon={faBars} style={{ marginLeft: 'auto' }}/>
            </Icon>
            {/*<Logo  style={{ marginLeft: 'auto' }} />*/}
            <Icon funcaoAbrirMenu={() => HandleLogout()} style={{ marginLeft: 'auto' }}>
                <FontAwesomeIcon icon={faSignOutAlt} />
            </Icon>
        </NavbarContainer>
    );
};

export default NavbarHorizontal;
