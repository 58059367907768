import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import TableList from '../../../components/modelo/TableList';

interface PessoaData {
    id: string;
    nome: string;
    sobrenome: string;
    telefone: string;
    documento: string;
    email: string;
    facebook: string;
    instagram: string;
    observacao: string;
}

const nomeDasColunas: string[] = [
    'Nome',
    'Sobrenome',
    'Celular',
    'Documento',
    'Email',
    'Facebook',
    'Instagram',
    'Observação'
];

const fetchData = async (setData: React.Dispatch<React.SetStateAction<PessoaData[]>>) => {
    try {
        const response = await api.get("/administrativo/pessoa/pessoaList", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET",
            },
        });
        setData(response.data);
    } catch (error) {
        console.error('Erro ao obter dados:', error);
    }
};

const IndexTableList: React.FC = () => {
    const [pessoas, setPessoas] = useState<PessoaData[]>([]);

    useEffect(() => {

        fetchData(setPessoas);
    }, []);

    const funcaoDesativar = async (userData: PessoaData) => {
        const proceed = window.confirm("Tem certeza de que deseja desativar este usuário?");
        if (proceed) {
            try {
                await api.post(
                    "/pessoa/pessoaDisabled/" + userData.id,
                    {},
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Methods": "POST",
                        },
                    }
                );
                fetchData(setPessoas); // Chama a função para buscar dados novamente
            } catch (error) {
                console.error('Erro ao desativar :', error);
            }
            console.log("Desativar usuário:", userData);
        }
    };

    return (
        <HomeAdm>
            <TableList
                funcaoDesativar={(dados) => funcaoDesativar(dados)}
                nomeDasColunas={nomeDasColunas}
                dadosVindosBackEnd={pessoas}
                habilitarColunaDeAcao={true}
                habilitarExclusao={true}
                linkParaEdicao = '/pessoaEdit/'
                keys={['nome', 'sobrenome', 'telefone', 'documento', 'email', 'facebook', 'instagram', 'observacao']}
                definicaoDeChavePrimaria='id'
                arrayColumns={[]}
                 urlClickBotao={"/administrativo/pessoa/pessoaCreate"}/>
        </HomeAdm>
    );
};

export default IndexTableList;
