import React, { useState, useEffect } from 'react';
import { useParams,redirect } from 'react-router-dom';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import FormularioGeneric, { FormData } from '../../../components/agrupamento/FormularioGeneric';
import { AxiosRequestConfig } from 'axios';



const IndexFormConfig: React.FC = ({  }) => {

    const { idGet } = useParams<{ idGet: string }>();

    const [dadosDoFormulario, setFormData] = useState({
        field: '',
        valor: '',
        descricao: '',
    });

    useEffect(() => {
        const funcaoBuscarDadosNoBD = async () => {
            try {
                const configData = await api.get(`/administrativo/config/configConsult/${idGet}`);

                if (configData && configData.data && configData.data.message) {
                    setFormData({
                        field: idGet || "",
                        valor: configData.data.message.valor || '',
                        descricao: configData.data.message.descricao || '',
                    });
                    return true;
                } else {
                    console.log('Erro ao buscar dados no banco de dados');
                    return true;
                }
            } catch (error) {
                console.error('Erro ao obter dados do usuário para edição:', error);
            }
        };

        if (idGet) {
            funcaoBuscarDadosNoBD().then((e) => {
                console.log(e);
            });
        } else {
            console.log('ID inválido:', idGet);
        }
    }, [idGet]);

    const data: FormData[] = [
        { type: "text", nomeLabel: 'Field', value: dadosDoFormulario.field, sm: 12, md: 6, lg: 12, onChange: (e: { target: { value: any; }; }) => setFormData({ ...dadosDoFormulario, field: e.target.value }) },
        { type: "text", nomeLabel: 'Valor', value: dadosDoFormulario.valor, sm: 12, md: 6, lg: 12, onChange: (e: { target: { value: any; }; }) => setFormData({ ...dadosDoFormulario, valor: e.target.value }) },
        { type: "text", nomeLabel: 'Descricao', value: dadosDoFormulario.descricao, sm: 12, md: 6, lg: 12, onChange: (e: { target: { value: any; }; }) => setFormData({ ...dadosDoFormulario, descricao: e.target.value }) },
    ];

    const GravarDados = async (dados: any) => {
        try {
            const fieldEnviada: AxiosRequestConfig = { params: { field: dados.field } };

            console.log('config');
            const rotaGetConfig = `/administrativo/config/configConsult/${dados.field}`;
            const funcaoBuscarDadosNoBD = await api.get(rotaGetConfig, fieldEnviada);

            const message = funcaoBuscarDadosNoBD.data.message;

            if (message === null) {
                const endpoint = '/administrativo/config/configCreate';

                const dadosEnvio = {
                    field: dados.field,
                    valor: dados.valor,
                    descricao: dados.descricao,
                };

                const result = await api.post(endpoint, dadosEnvio, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Methods': 'POST',
                    },
                });
                if (result.data) {
                    alert('Dados inseridos com sucesso!');
                    return redirect("/configuracaoList");
                }

            } else {
                // Se a mensagem não é false, realiza o UPDATE
                const endpointUpdate = `/administrativo/config/configEdit/${dados.field}`;

                const dadosEnvioUpdate = {
                    field: dados.field,
                    valor: dados.valor,
                    descricao: dados.descricao,
                };

                const resultUpdate = await api.post(endpointUpdate, dadosEnvioUpdate, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Methods': 'POST',
                    },
                });

                if (resultUpdate.data) {
                    alert('Dados inseridos com sucesso!');
                    return redirect("/administrativo/config/configuracaoList");
                }

                alert('Dados atualizados com sucesso!');
                return redirect("/administrativo/config/configuracaoList");
            }
        } catch (error) {
            console.error('Erro ao gravar/atualizar dados:', error);
        }
        return "";
    };

    return (
        <HomeAdm>
            <FormularioGeneric
                dadosDoFormulario={dadosDoFormulario}
                function_onClick={GravarDados}
                setFormData={setFormData}
                dataForm={data}
            />
        </HomeAdm>
    );
};

IndexFormConfig.defaultProps = {
    idGet: ''
};

export default IndexFormConfig;
