import React from 'react';
import TableRow from '../individual/TableRow';
import TableCell from '../individual/TableCell';
import TableDetails from '../agrupamento/TableDetails';
import InputGeneration from '../agrupamento/InputGeneration';
import CabecalhoBotaoDuplo from '../squad/CabecalhoBotaoDuplo';


interface Props {
    inputDataListaCompra: any;
    nomeDasColunas: string[];
    dadosVindosBackEnd: any[];
    urlClickBotao?: string;
    urlClickBotaoTransicaoTela?: string;
    nomeAdicionar?: string;
    nomeDoBotaoParaTransicaoDeTela?: string;
    nomeTitulo?: string;
    funcaoDesativar: (data?:any) => void;
    habilitarColunaDeAcao: boolean;
    habilitarExclusao: boolean;
    habilitarEdicao?: boolean;
    // funcaoBuscarDadosNoBD: () => void;
    keys: string[];
    linkParaEdicao: string;
    definicaoDeChavePrimaria: string;
    mostrarBotaoAdicionar?: boolean;
    idGet?: any;
}

const ListaItens: React.FC<Props> = ({
                                         inputDataListaCompra,
                                         nomeDasColunas,
                                         dadosVindosBackEnd,
                                         urlClickBotao = "/",
                                         urlClickBotaoTransicaoTela = "/",
                                         nomeAdicionar = "Adicionar",
                                         nomeDoBotaoParaTransicaoDeTela = "Ir para",
                                         nomeTitulo = "Lista",
                                         funcaoDesativar=(data?:any) => null,
                                         habilitarColunaDeAcao,
                                         habilitarExclusao,
                                         habilitarEdicao = true,
                                         // funcaoBuscarDadosNoBD,
                                         keys,
                                         linkParaEdicao,
                                         definicaoDeChavePrimaria,
                                         mostrarBotaoAdicionar = true,
                                            idGet
                                     }) => (
    <>
        <CabecalhoBotaoDuplo
            urlClickBotao={urlClickBotao}
            urlClickBotaoTransicaoTela={urlClickBotaoTransicaoTela}
            nomeAdicionar={nomeAdicionar}
            nomeDoBotaoParaTransicaoDeTela={nomeDoBotaoParaTransicaoDeTela}
            nomeTitulo={nomeTitulo}
            mostrarBotaoAdicionar={mostrarBotaoAdicionar}
        />
        <InputGeneration Fields={inputDataListaCompra} />

        <TableDetails
            arrayColumns={[]}
            keys={keys}
            linkParaEdicao={linkParaEdicao}
            nomeDasColunas={nomeDasColunas}
            dadosVindosBackEnd={dadosVindosBackEnd}
            funcaoDesativar={funcaoDesativar}
            // funcaoBuscarDadosNoBD={funcaoBuscarDadosNoBD}
            habilitarColunaDeAcao={habilitarColunaDeAcao}
            habilitarExclusao={habilitarExclusao}
            habilitarEdicao={habilitarEdicao}
            definicaoDeChavePrimaria={definicaoDeChavePrimaria}
            onClickLinhaTabela={() => null}
        >
            {dadosVindosBackEnd.length>0 ? (
                console.log(dadosVindosBackEnd),
                    dadosVindosBackEnd.map((data, index) => (
                        <TableRow key={index}>
                            <TableCell data={data} keys={nomeDasColunas} />
                        </TableRow>
                    ))
            ) : (
                <TableRow>
                    <TableCell key={null} keys={[]} data={[]}  colSpan={nomeDasColunas.length} align="center"></TableCell>
                </TableRow>
            )}
        </TableDetails>
    </>
);

export default ListaItens;
