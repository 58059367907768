import React, { ReactNode } from 'react';

type TableProps = {
    children: ReactNode;
};

const Table: React.FC<TableProps> = (props) => {
    return <table style={{ width: '100%' }}>{props.children}</table>;
};

export default Table;
