import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import HomeAdm from '../../../components/modelo/HomeAdm';
import TableList from '../../../components/modelo/TableList';




const nomeDasColunas: string[] = [
    'Field',
    'Valor',
    'Descrição',
];

const fetchData = async (setData: (data: any[]) => void) => {
    try {
        const response = await api.get("/administrativo/config/configList", {
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json',
                "Access-Control-Allow-Methods": "GET",
            },
        });
        setData(response.data);
    } catch (error) {
        console.error('Erro ao obter dados:', error);
    }
};

const IndexConfigList: React.FC = () => {

    
    const [config, setConfig] = useState<any[]>([]);
    console.log({'config': config});
    useEffect(() => {
        fetchData(setConfig);
    }, []);

    return (

        <HomeAdm>
            <TableList
                nomeDasColunas={nomeDasColunas}
                dadosVindosBackEnd={config}
                habilitarColunaDeAcao={true}
                habilitarExclusao={false}
                linkParaEdicao='/administrativo/config/configuracaoEdit/'
                nomeTitulo='Lista de Configurações'
                nomeAdicionar='Adicionar Configuração'
                urlClickBotao='/configuracaoCreate/'
                keys={['field', 'valor', 'descricao']}
                definicaoDeChavePrimaria='field'
                arrayColumns={[]}
            />
        </HomeAdm>
    );
};

export default IndexConfigList;
