import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import LoginIndexLayout from "../../../components/modelo/login/LoginIndexLayout";

const LoginIndex: React.FC = () => {
    return (
        <LoginIndexLayout />
    );
};

export default LoginIndex;
