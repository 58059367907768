import React from 'react';

import ButtonMedio from '../individual/ButtonMedio';
import Title from '../individual/Title';
import {redirect,useNavigate} from "react-router-dom";

interface CabecalhoBasicoProps {
    urlClickBotao: string;
    nomeTitulo: string;
    mostrarBotaoAdicionar: boolean;
    nomeAdicionar: string;
};

const CabecalhoBasico: React.FC<CabecalhoBasicoProps> = (props) => {

    const navigate = useNavigate();
    const routeButton = () => {
        console.log("feito onclick");

        navigate(props.urlClickBotao);
        // return redirect(props.urlClickBotao);
        // history.push(props.urlClickBotao);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
            <Title titulo={props.nomeTitulo}></Title>
            {props.mostrarBotaoAdicionar && (
                <ButtonMedio variant='primary' onClick={routeButton} nameButton={props.nomeAdicionar}></ButtonMedio>
            )}
        </div>
    );
};

export default CabecalhoBasico;
