// components/MainContainer.js
import React from 'react';
import styled from 'styled-components';

const MainContainerStyled = styled.main`
  flex-grow: 1;
  padding: 20px;
`;

const MainContainer = ({ children }) => {
    return <MainContainerStyled>{children}</MainContainerStyled>;
};

export default MainContainer;
